<template>
  <b-form-group
    class="px-input"
    :class="{ dense }"
    :content-cols="inputCols"
    :label="label"
    label-class="px-input__label"
    :label-cols="labelCols"
    :label-for="label"
  >
    <b-form-input
      v-if="type !== 'checkbox'"
      :id="label"
      :autofocus="autofocus"
      class="px-input__element"
      :class="{ 'px-input__element-invalid': invalid }"
      :debounce="$attrs.debounce"
      :disabled="$attrs.disabled"
      :invalid="invalid"
      :placeholder="$attrs.placeholder"
      :plaintext="$attrs.plaintext"
      :max-length="$attrs.maxLength"
      :type="type"
      :value="value"
      v-on="$listeners"
    />
    <b-form-checkbox
      v-else
      :id="label"
      :checked="value"
      class="px-input__element"
      :class="{ 'px-input__element-invalid': invalid }"
      :debounce="$attrs.debounce"
      :disabled="$attrs.disabled"
      :invalid="invalid"
      :plaintext="$attrs.plaintext"
      v-on="$listeners"
    />
    <div v-if="invalid" class="px-input__invalid-feedback">
      {{ invalidFeedback }}
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: 'PxInput',
  props: {
    value: {
      default: '',
      required: false,
      type: [String, Number],
    },
    invalid: {
      default: false,
      type: Boolean,
    },
    invalidFeedback: {
      default: '',
      type: String,
    },
    inputCols: {
      default: 7,
      required: false,
      type: Number,
    },
    label: {
      default: '',
      required: false,
      type: String,
    },
    labelCols: {
      default: 5,
      required: false,
      type: [Number, String],
    },
    type: {
      default: 'text',
      required: false,
      type: String,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-input {
  margin: 4px 0;

  ::v-deep &__label {
    font-weight: 700;
    margin: auto;
    padding: 4px 0 !important;
  }

  ::v-deep .col-* {
    padding: 0;
  }

  &__element {
    border-width: 0;
    border-bottom: solid 2px $border-grey;
    padding: 4px;
    height: unset;

    &:active,
    &:focus {
      outline-width: 0;
      border-bottom-color: $polly-light-blue;
    }

    &-invalid,
    &-invalid:active,
    &-invalid:focus {
      border-bottom-color: $polly-orange;
    }

    &:read-only {
      border-width: 0;
      cursor: default;
    }
  }

  &__invalid-feedback {
    color: $polly-orange;
    text-align: end;
  }

  &.dense {
    margin: 0;

    ::v-deep .px-input__label {
      padding: 0 !important;
    }

    .px-input__element {
      padding: 0 0.5rem;
    }
  }
}
</style>
