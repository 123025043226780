<template>
  <div>
    <div v-if="!message.user" class="chat-widget__message-sender">
      <div class="chat-message-box">
        <img
          alt="Profile Image"
          class="chat-profile-image"
          :src="otherProfileImage"
        />
        <span
          :class="{
            'chat-widget__message-bubble': true,
            'chat-widget__message-bubble--other': true,
            'chat-widget__message-bubble--streaming':
              isLastMessage && isStreaming,
          }"
        >
          <span v-text="message.text"></span>
          <router-link v-if="message.link" :to="message.link">
            <span v-text="message.linkText"></span>
          </router-link>
          <span
            v-if="isLastMessage && isStreaming"
            class="chat-widget__cursor"
          ></span>
        </span>
        <message-reaction v-if="message.id" :message="message" />
      </div>
    </div>
    <span v-if="message.user" class="chat-widget__message-bubble">
      <span v-text="message.text"></span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageReaction from './MessageReaction.vue';
import { COPILOT_GETTERS } from '../../store/modules/copilot';

export default {
  name: 'ChatMessage',
  components: {
    MessageReaction,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    otherProfileImage: {
      type: String,
      required: true,
    },
    isLastMessage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('copilot', {
      isStreaming: COPILOT_GETTERS.isStreaming,
    }),
  },
};
</script>

<style lang="scss" scoped>
.chat-widget__message-bubble--streaming {
  position: relative;
  white-space: pre-wrap;
  word-break: break-word;
}

.chat-widget__cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: currentColor;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
  vertical-align: text-bottom;
  opacity: 0.5;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
}
</style>
