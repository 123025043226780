export * from './common';

import { formatMoney } from '@shared/utils/converters.js';

export function invertObject(obj) {
  return Object.entries(obj).reduce((ret, entry) => {
    const [key, value] = entry;
    ret[value] = key;
    return ret;
  }, {});
}

/**
 * Used to create the dropdown options arrays with 2 objects that match PE3 enums
 * @param {Object} options Enums and their ids
 * @param {Object} labels Enums and their text labels to be displayed
 * @returns an array that matches the options and labels with each item in the array containing
 *          an object: {id, text}
 */
const createDropdownArr = (options, labels) => {
  return Object.freeze(
    Object.keys(options).reduce((result, key) => {
      result.push({ id: options[key], text: labels[key] });
      return result;
    }, []),
  );
};

export const requestStatus = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
});

export const exchangeRefinanceTypes = Object.freeze({
  '0': 'None',
  '2': 'Cash Out',
  '3': 'Rate Term',
  '4': 'Home Improvement',
  '5': 'Debt Consolidation',
  '6': 'Other',
});

export const PERefinaceTypesLabels = Object.freeze({
  None: 'None',
  NoCashOut: 'No Cash-Out',
  CashOut: 'Cash-Out',
  LimitedCashOut: 'Limited Cash-Out / Rate Term',
  HomeImprovement: 'Home Improvement',
  DebtConsolidation: 'Debt Consolidation',
  Other: 'Other',
});
export const refinanceTypes = Object.freeze({
  [PERefinaceTypesLabels.NoCashOut]: 1,
  [PERefinaceTypesLabels.CashOut]: 2,
  [PERefinaceTypesLabels.LimitedCashOut]: 3,
  [PERefinaceTypesLabels.HomeImprovement]: 4,
  [PERefinaceTypesLabels.DebtConsolidation]: 5,
  [PERefinaceTypesLabels.Other]: 6,
});

export const purposeCodes = Object.freeze({
  '1': 'Purchase',
  '2': 'Refinance',
});

export const loanPurposeTypes = Object.freeze({
  Purchase: 1,
  Refinance: 2,
  'No Cash-Out Refi': 6,
  'Cash-Out Refi': 7,
  Construction: 3,
  'Construction Permit': 4,
  Other: 5,
});

export const occupancyCodes = Object.freeze({
  '1': 'Primary',
  '2': 'Secondary',
  '3': 'Investment',
});

export const compPaidByTypes = Object.freeze({
  LENDER: 1,
  BORROWER: 2,
});

export const compPaidBy = Object.freeze({
  [compPaidByTypes.LENDER]: 'Lender',
  [compPaidByTypes.BORROWER]: 'Borrower',
});

export const BID_TAPE_DOWNLOAD_FORMATS = Object.freeze({
  XLSX: 'XLSX',
  CSV: 'CSV',
});

export const STATES = Object.freeze({
  Alabama: { abbrev: 'AL', fips: '01' },
  Alaska: { abbrev: 'AK', fips: '02' },
  Arizona: { abbrev: 'AZ', fips: '04' },
  Arkansas: { abbrev: 'AR', fips: '05' },
  California: { abbrev: 'CA', fips: '06' },
  Colorado: { abbrev: 'CO', fips: '08' },
  Connecticut: { abbrev: 'CT', fips: '09' },
  Delaware: { abbrev: 'DE', fips: '10' },
  'District of Columbia': { abbrev: 'DC', fips: '11' },
  Florida: { abbrev: 'FL', fips: '12' },
  Georgia: { abbrev: 'GA', fips: '13' },
  Hawaii: { abbrev: 'HI', fips: '15' },
  Idaho: { abbrev: 'ID', fips: '16' },
  Illinois: { abbrev: 'IL', fips: '17' },
  Indiana: { abbrev: 'IN', fips: '18' },
  Iowa: { abbrev: 'IA', fips: '19' },
  Kansas: { abbrev: 'KS', fips: '20' },
  Kentucky: { abbrev: 'KY', fips: '21' },
  Louisiana: { abbrev: 'LA', fips: '22' },
  Maine: { abbrev: 'ME', fips: '23' },
  Maryland: { abbrev: 'MD', fips: '24' },
  Massachusetts: { abbrev: 'MA', fips: '25' },
  Michigan: { abbrev: 'MI', fips: '26' },
  Minnesota: { abbrev: 'MN', fips: '27' },
  Mississippi: { abbrev: 'MS', fips: '28' },
  Missouri: { abbrev: 'MO', fips: '29' },
  Montana: { abbrev: 'MT', fips: '30' },
  Nebraska: { abbrev: 'NE', fips: '31' },
  Nevada: { abbrev: 'NV', fips: '32' },
  'New Hampshire': { abbrev: 'NH', fips: '33' },
  'New Jersey': { abbrev: 'NJ', fips: '34' },
  'New Mexico': { abbrev: 'NM', fips: '35' },
  'New York': { abbrev: 'NY', fips: '36' },
  'North Carolina': { abbrev: 'NC', fips: '37' },
  'North Dakota': { abbrev: 'ND', fips: '38' },
  Ohio: { abbrev: 'OH', fips: '39' },
  Oklahoma: { abbrev: 'OK', fips: '40' },
  Oregon: { abbrev: 'OR', fips: '41' },
  Pennsylvania: { abbrev: 'PA', fips: '42' },
  'Rhode Island': { abbrev: 'RI', fips: '44' },
  'South Carolina': { abbrev: 'SC', fips: '45' },
  'South Dakota': { abbrev: 'SD', fips: '46' },
  Tennessee: { abbrev: 'TN', fips: '47' },
  Texas: { abbrev: 'TX', fips: '48' },
  Utah: { abbrev: 'UT', fips: '49' },
  Vermont: { abbrev: 'VT', fips: '50' },
  Virginia: { abbrev: 'VA', fips: '51' },
  Washington: { abbrev: 'WA', fips: '53' },
  'West Virginia': { abbrev: 'WV', fips: '54' },
  Wisconsin: { abbrev: 'WI', fips: '55' },
  Wyoming: { abbrev: 'WY', fips: '56' },
});

export const US_TERRITORIES = Object.freeze({
  'American Samoa': { abbrev: 'AS', fips: '60' },
  Guam: { abbrev: 'GU', fips: '66' },
  'Northern Mariana Islands': { abbrev: 'MP', fips: '69' },
  'Puerto Rico': { abbrev: 'PR', fips: '72' },
  'U.S. Virgin Islands': { abbrev: 'VI', fips: '78' },
});

export const STATES_WITH_TERRITORIES = Object.freeze({
  ...STATES,
  ...US_TERRITORIES,
});

export const propertyCodes = Object.freeze({
  '1': 'Single Family',
  '2': 'Condo',
  '3': 'PUD',
  '5': 'Mobile',
  '6': 'Multi Unit',
  '7': 'Co-op',
  '8': 'Townhouse',
  '9': 'Multi Family',
  '10': 'Commercial',
  '11': 'Mixed Use',
  '12': 'Farm',
  '13': 'Home Business',
  '14': 'Home Land',
  '15': 'Manufactured Single',
  '16': 'Manufactured Double',
});

export const propertyAttachmentCodes = Object.freeze({
  '0': 'Unspecified',
  '1': 'Detached',
  '2': 'Attached',
});

export const US_TIME_ZONES = Object.freeze({
  ALASKA: 'America/Anchorage',
  CENTRAL: 'America/Chicago',
  EASTERN: 'America/New_York',
  HAWAII: 'Pacific/Honolulu',
  MOUNTAIN: 'America/Denver',
  PACIFIC: 'America/Los_Angeles',
});

export const US_TIME_ZONES_DEPRECIATED = Object.freeze({
  ALASKA: 'US/Alaska',
  CENTRAL: 'US/Central',
  EASTERN: 'US/Eastern',
  HAWAII: 'US/Hawaii',
  MOUNTAIN: 'US/Mountain',
  PACIFIC: 'US/Pacific',
});

export const bidStatus = Object.freeze({
  active: 'ACTIVE',
  countered: 'COUNTERED',
  cancelled: 'CANCELLED',
  accepted: 'ACCEPTED',
  selected: 'SELECTED',
});

export const inviteStatus = Object.freeze({
  approved: 'Approved',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
});

export const YES = 'Yes';
export const NO = 'No';

export const NEW_ID = 'new';
export const SYSTEM_PARAM_ID = 'system';

export const NOTIFICATION_STATUS = Object.freeze({
  READ: 'READ',
  UNREAD: 'UNREAD',
});

export const IMPOUNDS = Object.freeze({
  '0': NO,
  '1': YES,
  '2': YES,
});

export const LOAN_STATUS = Object.freeze({
  AVAILABLE: 'Available',
  ARCHIVED: 'Archived',
});

export const loanStatus = Object.freeze({
  unspecified: 0,
  unavailable: 1,
  available: 2,
  sold: 3,
  confirmed: 4,
  archived: 5,
  selected: 6,
});

export const intToLoanStatus = invertObject(loanStatus);

export const umBenchmarks = Object.freeze({
  UM10: 'UM10',
  UM15: 'UM15',
  UM20: 'UM20',
  UM30: 'UM30',
});
export const gBenchmarks = Object.freeze({
  G210: 'G210',
  G215: 'G215',
  G220: 'G220',
  G230: 'G230',
  GN10: 'GN10',
  GN15: 'GN15',
  GN20: 'GN20',
  GN30: 'GN30',
});
export const benchmarks = { ...umBenchmarks, ...gBenchmarks };

export const ginnieDynamicProductCodes = [
  'G230HIGH',
  'G230LOW',
  'G215HIGH',
  'G215LOW',
];

// The following 2 temp buydown are silly weird constants, that are seemingly doing the same thing? (except one is missing none??)
// I'm going to ignore them for now because they're used in other places, but we should refactor these and trash them
export const temporaryBuydownTypeOptions = Object.freeze({
  THREE_TWO_ONE: '3-2-1',
  TWO_ONE: '2-1',
  ONE_ONE: '1-1',
  ONE_ZERO: '1-0',
});
export const temporaryBuydownCodes = Object.freeze({
  '0': 'None',
  '1': '3-2-1',
  '2': '2-1',
  '3': '1-1',
  '4': '1-0',
});
// I kept naming on this one (moved from above) but these are the PE3 naming & values
export const temporaryBuydownPeStringToEnum = Object.freeze({
  None: 0,
  ThreeTwoOne: 1,
  TwoOne: 2,
  OneOne: 3,
  OneZero: 4,
});
export const PETemporaryBuydownTypeLabels = Object.freeze({
  None: 'None',
  ThreeTwoOne: '3-2-1',
  TwoOne: '2-1',
  OneOne: '1-1',
  OneZero: '1-0',
});

export const PETemporaryBuydownTypeOptions = createDropdownArr(
  temporaryBuydownPeStringToEnum,
  PETemporaryBuydownTypeLabels,
);

export const benchmarkRates = Object.freeze([
  0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4, 4.5, 5, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0,
  8.5, 9.0, 9.5,
]);

function getBenchmarks(benchmarkNames) {
  // An array of available options for dropdown
  const options = [{ text: '', value: 'null' }];
  // Dictionary to parse values from dropdown options to benchmark and rate
  const values = { null: { benchmark_name: null, benchmark_rate: null } };
  // create options in the following way:
  // text: "<benchmarkName> <benchmarkRate>" or empty string '' for absent benchmark
  // value: <benchmarkName>_<benchmarkRate>"
  for (const benchmarkRate of benchmarkRates) {
    for (const benchmarkName of benchmarkNames) {
      const benchmarkValue = `${benchmarkName}_${benchmarkRate}`;
      options.push({
        text: `${benchmarkName} ${benchmarkRate.toFixed(1)}`,
        value: benchmarkValue,
      });
      values[benchmarkValue] = {
        benchmark_name: benchmarkName,
        benchmark_rate: benchmarkRate,
      };
    }
  }
  // sorting options by text alphabetically
  options.sort((a, b) => a.text.localeCompare(b.text));
  return {
    options,
    values,
  };
}

const umBenchmarkGroups = getBenchmarks(Object.values(umBenchmarks));
export const umBenchmarkOptions = umBenchmarkGroups.options;
export const umBenchmarkValues = umBenchmarkGroups.values;

const gBenchmarkGroups = getBenchmarks(Object.values(gBenchmarks));
export const gBenchmarkOptions = gBenchmarkGroups.options;
export const gBenchmarkValues = gBenchmarkGroups.values;

const exceptionalPrograms = ['FHA', 'VA', 'USDA'];

export function getBenchmarkOptions(loanProgram) {
  return exceptionalPrograms.some(program => loanProgram?.includes(program))
    ? gBenchmarkOptions
    : umBenchmarkOptions;
}

export function getBenchmarkValues(loanProgram) {
  return exceptionalPrograms.some(program => loanProgram.includes(program))
    ? gBenchmarkValues
    : umBenchmarkValues;
}

export const pageSelectorOptions = [
  { text: 5, value: 5 },
  { text: 10, value: 10 },
  { text: 25, value: 25 },
  { text: 50, value: 50 },
  { text: 75, value: 75 },
  { text: 100, value: 100 },
  { text: 150, value: 150 },
  { text: 200, value: 200 },
  { text: 250, value: 250 },
  { text: 500, value: 500 },
];

export const DEFAULT_PAGE_OPTIONS = pageSelectorOptions.map(o => ({
  id: o.value,
  text: o.text,
}));

export const commitmentTypes = Object.freeze({
  '0': 0,
  '1': 'MD',
  '2': 'BE',
  '3': 'AT',
  '4': 'BB',
});

export const commitmentTypesFull = Object.freeze({
  '1': 'Mandatory',
  '2': 'Best Efforts',
  '3': 'Assignment of Trade',
  '4': 'Bulk Bid',
});

export const COMMITMENT_TYPE_OPTIONS = Object.keys(commitmentTypesFull).map(
  c => ({
    value: parseInt(c),
    label: commitmentTypesFull[c],
  }),
);

export const servicingProviderRetained = 'Retained';
export const crxTickerSymbol = 'CX';
export const servicingOptionsExtended = Object.freeze({
  Retained: 'RT',
});

export const agencyTickersFannie = 'FNMA';
export const agencyTickersFreddie = 'FHLM';
export const agencyTickersGinnie = 'GNMA';
export const agencyTickers = Object.freeze([
  agencyTickersFannie,
  agencyTickersFreddie,
]);

export const umbsTickersFannie = 'FNUM';
export const umbsTickersFreddie = 'FHUM';
export const umbsTickers = Object.freeze([
  umbsTickersFannie,
  umbsTickersFreddie,
]);

export const medianIncomeRanks = Object.freeze({
  '0': 'Unspecified',
  '1': 'Low',
  '2': 'Moderate',
});

export const SERVICING_TYPE_UNSPECIFIED = 0;
export const SERVICING_TYPE_RETAINED = 1;
export const SERVICING_TYPE_RELEASED = 2;

export const servicingTypes = Object.freeze({
  '0': 'Unspecified',
  '1': 'Retained',
  '2': 'Released',
});

export const SERVICING_TYPE_OPTIONS = Object.keys(servicingTypes)
  .filter(c => parseInt(c) !== SERVICING_TYPE_UNSPECIFIED)
  .map(c => ({
    value: parseInt(c),
    label: servicingTypes[c],
  }));

export const servicingPricingTypes = Object.freeze({
  PricePoints: 'PricePoints',
  PurchasePricePoints: 'PurchasePricePoints',
});

export const servicingSourceTypes = Object.freeze({
  File: 'File',
  API: 'API',
});

export const lockDeskStatus = Object.freeze({
  closed: 'closed',
  opened: 'opened',
  suspended: 'suspended',
});

export const COISSUER_NONE = 0;

export const POOL_AMORTIZATION_TYPES = ['Adjustable Rate', 'Fixed'];

export const FREDDIE_PAYUP_CASH_POOL_MAP = Object.freeze({
  fee_85k: 'Max LLB <= 85,000',
  fee_110k: 'Max LLB <= 110,000',
  fee_125k: 'Max LLB <= 125,000',
  fee_150k: 'Max LLB <= 150,000',
  fee_175k: 'Max LLB <= 175,000',
  fee_200k: 'Max LLB <= 200,000',
  fee_225k: 'Max LLB <= 225,000',
  fee_250k: 'Max LLB <= 250,000',
  fee_275k: 'Max LLB <= 275,000',
  fee_fico: 'FICO < 700',
  fee_inv: 'Investment',
  fee_ny: 'State NY',
  fee_sc: 'Super Conforming',
  fee_green: 'Green',
  fee_home_possible: 'Home Possible',
  fee_fl: 'State FL',
  fee_second_home: 'Second Home',
  fee_tx: 'State TX',
  fee_ny_sc: 'State NY SuperConforming',
});

export const commitmentPoolStatusValueMapping = Object.freeze({
  unspecified: 0,
  error: 1,
  created: 2,
  requested: 3,
  accepted: 4,
  rejected: 5,
  closed: 6,
});

export const commitmentPoolStatusTextMapping = Object.freeze({
  '0': 'Unspecified',
  '1': 'Error',
  '2': 'Created',
  '3': 'Requested',
  // Note: This is accepted, however we want the text to display "Committed" instead.
  '4': 'Committed',
  '5': 'Rejected',
  '6': 'Closed',
});

export const writebackStatus = Object.freeze({
  unspecified: 0,
  successful: 1,
  failed: 2,
  pending: 3,
  blocked: 4,
});

export const autobidBuyer = Object.freeze({
  unspecified: 0,
  freddieMac: 1,
  fannieMae: 2,
});

export const between_op = 'range';
export const in_ops = ['in', 'datetime_in', 'not_in', 'datetime_not_in'];
export const opsOptions = [
  { value: '<', text: 'is less than' },
  { value: '>', text: 'is greater than' },
  { value: '=', text: 'is equal to' },
  { value: '!=', text: 'is not equal to' },
  { value: 'datetime=', text: 'is equal to' },
  { value: 'datetime!=', text: 'is not equal to' },
  { value: '>=', text: 'is greater than or equal to' },
  { value: '<=', text: 'is less than or equal to' },
  { value: 'datetime<=', text: 'is less than or equal to' },
  { value: 'string=', text: 'is equal to' },
  { value: 'string!=', text: 'is not equal to' },
  { value: 'contains', text: 'contains' },
  { value: 'in', text: 'is either' },
  { value: 'not_in', text: 'is not either' },
  { value: 'datetime_in', text: 'is either' },
  { value: 'datetime_not_in', text: 'is not either' },
  { value: between_op, text: 'is between' },
  { value: 'startswith', text: 'starts with' },
  { value: 'endswith', text: 'ends with' },
];
export const boolTrueSelectValue = 'true';
export const boolOptions = [
  { text: YES, id: boolTrueSelectValue },
  { text: NO, id: 'false' },
];

export const eligibilityRuleType = Object.freeze({
  '1': 'Ineligible',
  '2': 'Only eligible',
});

export const MAX_CONTRACT_DAYS = 90;
export const MAX_EXTENSION_DAYS = 30;

const UPFRONT_FEE_CAP_DISPLAY_NAME = 'Agency LLPA Cap';
// NOTE: The keys for the text are the function names
// used to calculate the LLPAs
// Function names can be found in:
// buy/services/autobid/fanniemae/llpa_calculator.py
export const FANNIE_LLPA_NAME_MAPPING = Object.freeze({
  arm_fee: 'Conv ARM/LTV',
  cash_out_refi_fee: 'Conv Cashout LTV/FICO',
  condo_fee: 'Conv Condo',
  fico_ltv_fee: 'Conv LTV/FICO',
  hb_arm_fee: 'Conv HB ARM',
  hb_cash_out_refi_fee: 'Conv HB Cashout',
  hb_purchase_limited_refi_fee: 'Conv HB Purch/RT',
  high_ltv_refi_llpa_cap: 'Conv High LTV Refi Cap',
  home_ready_cap: 'Conv HomeReady LLPA Cap',
  home_style_energy_fee: 'Conv Homestyle Energy',
  housing_counseling_fee: 'Conv HomeReady Counseling',
  investment_fee: 'Conv Occupancy/LTV',
  manufactured_fee: 'Conv Manufactured/LTV',
  min_mortgage_insurance_fee: 'Conv Minimum MI', // For legacy data.
  min_mortgage_insurance_fee_over_20: '>20 year Conv Minimum MI',
  min_mortgage_insurance_fee_under_20: '<=20 year Conv Minimum MI',
  multi_unit_fee: 'Conv Multiple Unit',
  second_home_fee: 'Conv Occupancy/LTV',
  subordinate_financing_fee: 'Conv Sub. Financing',
  adverse_market_refi_fee: 'Adverse Market Refinance Fee',
  upfront_fee_cap: UPFRONT_FEE_CAP_DISPLAY_NAME,
  waived_for_special_codes: UPFRONT_FEE_CAP_DISPLAY_NAME,
});

// NOTE: The keys for the text are the function names
// used to calculate the LLPAs
// Function names can be found in:
// buy/services/autobid/freddiemac/llpa_calculator.py
export const FREDDIE_LLPA_NAME_MAPPING = Object.freeze({
  a_minus_lpa_fee: 'A Minus LPA Fee',
  a_minus_non_lpa_fee: 'A Minus Non LPA Fee',
  arm_fee: 'Conv High LTV ARM',
  cashout_fico_ltv_fee: 'Conv Cashout LTV/FICO',
  condo_fee: 'Conv Condo',
  custom_mi_fee: 'Conv Minimum MI', // For legacy data.
  custom_mi_fee_over_20y: '>20 year Conv Minimum MI',
  custom_mi_fee_fixed_under_20y: '<=20 year Conv Minimum MI',
  greenchoice_fee: 'Conv GreenChoice',
  high_ltv_fee: 'High LTV Fee',
  investment_fee: 'Conv Occupancy/LTV',
  home_possible_cap: 'Conv HomePossible LLPA Cap',
  // NOTE: This replaced the generic llpa_cap.
  // This isn't being used, however, defaults to this.
  enhanced_relief_cap: 'Conv HomePossible LLPA Cap',
  manufactured_fee: 'Conv Manufactured/LTV',
  non_relief_fico_ltv_fee: 'Non Relief Fico LTV Fee',
  non_relief_secondary_fee: 'Conv Sub. Financing',
  num_units_fee: 'Conv Multiple Unit',
  relief_fico_ltv_fee: 'Relief Fico LTV Fee',
  relief_secondary_fee: 'Conv Sub. Financing',
  second_home_fee: 'Conv Occupancy/LTV',
  super_conforming_fee: 'Conv Super Conforming',
  market_condition_fee: 'Adverse Market Refinance Fee',
  upfront_fee_cap: UPFRONT_FEE_CAP_DISPLAY_NAME,
});

export const LLPA_NAMES_MAPPING = Object.freeze({
  [agencyTickersFannie]: FANNIE_LLPA_NAME_MAPPING,
  [agencyTickersFreddie]: FREDDIE_LLPA_NAME_MAPPING,
});

export const INTERNAL_ERROR_MSG = 'Internal error. Please try again';

export const SETTLEMENT_CUTOFF_MAX = 30;

// States if all loan tables in commitment cart should be hidden, shown,
// or left to individual cart to display or show their respective tables
export const commitmentCartTablesVisibilityModes = Object.freeze({
  ALL_TABLES_SHOWN: 'ALL_LOANS_SHOWN',
  ALL_TABLES_HIDDEN: 'ALL_LOANS_HIDDEN',
  TABLES_SHOWN_AND_HIDDEN: 'LOANS_SHOWN_AND_HIDDEN',
});

// Pool Tolerance configuration
export const TOLERANCE_HALF_WIDTH = 2.5;
export const HIGH_LEVEL_TOLERANCE = 100 + TOLERANCE_HALF_WIDTH;
export const LOW_LEVEL_TOLERANCE = 100 - TOLERANCE_HALF_WIDTH;
export const TOLERANCE_WIDTH = HIGH_LEVEL_TOLERANCE - LOW_LEVEL_TOLERANCE;

// Pools in an accepted state within the cart are considered forward pools
// regardless if the pool was manually created or auto generated.
export const isForwardPool = pool =>
  pool.status === commitmentPoolStatusValueMapping.accepted;

export const GET_ASYNC_TASK_INTERVAL = 2500;
export const GET_ASYNC_TASK_INTERVAL_SHORT = 1000;
// max attempts is calculated as 10 minutes in ms divided by check task interval
export const GET_ASYNC_TASK_MAX_ATTEMPTS =
  (10 * 60 * 1000) / GET_ASYNC_TASK_INTERVAL;

export const DEFAULT_TABLE_OPTIONS = {
  pageIndex: 1,
  pageSize: 25,
  sortBy: '',
  sortDesc: true,
  total: 0,
};

export const PAYUPS_TABLE_TERM_OBJ_KEY = {
  '180': { key: '15_Year', label: '15 Year', termFilterKey: 180 },
  '360': { key: '30_Year', label: '30 Year', termFilterKey: 360 },
};

export const yesNoLabels = Object.freeze({
  true: YES,
  false: NO,
});

export const yesNoOptions = Object.freeze([
  { id: true, text: YES },
  { id: false, text: NO },
]);

export const lockPeriods = Object.freeze({
  '15': '15 Day Lock',
  '30': '30 Day Lock',
  '45': '45 Day Lock',
  '60': '60 Day Lock',
});
export const pricerModes = Object.freeze({
  lock: 'lock',
  scenario: 'scenario',
  reprice: 'reprice',
  relock: 'relock',
  analysis: 'analysis',
  floatDown: 'floatDown',
  productChange: 'productChange',
});
export const amortizationMap = Object.freeze({
  FIXED: 'Fixed',
  ARM: 'ARM',
  BALLOON: 'Balloon',
});

export const terms = Object.freeze({
  TERM_480: 480,
  TERM_360: 360,
  TERM_348: 348,
  TERM_336: 336,
  TERM_324: 324,
  TERM_312: 312,
  TERM_300: 300,
  TERM_288: 288,
  TERM_276: 276,
  TERM_264: 264,
  TERM_252: 252,
  TERM_240: 240,
  TERM_228: 228,
  TERM_216: 216,
  TERM_204: 204,
  TERM_192: 192,
  TERM_180: 180,
  TERM_168: 168,
  TERM_156: 156,
  TERM_144: 144,
  TERM_132: 132,
  TERM_120: 120,
  TERM_108: 108,
  TERM_96: 96,
  TERM_84: 84,
  TERM_72: 72,
  TERM_60: 60,
  TERM_48: 48,
  TERM_36: 36,
  TERM_24: 24,
  TERM_12: 12,
  TERM_9: 9,
  TERM_6: 6,
  TERM_3: 3,
});

export const armIndexMap = Object.freeze({
  CMT1: 2,
  CMT3: 3,
  CMT5: 4,
  CMT10: 5,
  CMT7: 6,
  SOFR_1M: 200,
  SOFR_3M: 201,
  SOFR_6M: 202,
  WSJ_PRIME_RATE: 1202,
});

export const armIndexSelect = Object.freeze(
  Object.keys(armIndexMap).map(k => ({ id: armIndexMap[k], text: k })),
);

export const loanTypes = Object.freeze({
  Conventional: 1,
  FHA: 2,
  VA: 3,
  USDA: 4,
  Jumbo: 5,
  NonQM: 8,
  HELOC: 9,
});

export const loanSubTypes = Object.freeze({
  Standard: 'Standard',
  Affordable: 'Affordable',
  Renovation: 'Renovation',
  HFA: 'HFA',
  StreamlineCQ: 'Streamline CQ',
  StreamlineNCQ: 'Streamline NCQ',
  SimpleRefi: 'Simple Refi',
  HUD100Down: 'HUD $100 Down',
  HUD184: 'HUD 184',
  IRRRLCQ: 'IRRRL CQ',
  IRRRLNCQ: 'IRRRL NCQ',
  StreamlineAssist: 'Streamline Assist',
});

export const LoanSubTypesSelect = Object.entries(loanSubTypes).map(
  ([key, value]) => ({
    id: key,
    text: value,
  }),
);

export const loanCategories = Object.freeze({
  Conforming: 'Conforming',
  HighBalance: 'High Balance',
  NonAgency: 'Non Agency',
});

export const loanCategoriesSelect = Object.freeze(
  Object.keys(loanCategories).map((k, index) => ({
    id: index + 1,
    text: loanCategories[k],
  })),
);

export const versionsFromChoices = Object.freeze({
  pastWeek: 'This Past Week',
  pastMonth: 'This Past Month',
  custom: 'Custom',
});

export const publishTypeChoices = Object.freeze({
  all: 'All',
  autoPublished: 'Auto Published',
  manual: 'Manual',
});

export const ConfigurationStatuses = Object.freeze({
  DRAFT: 'Draft',
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
});

export const AuditEntriesActions = Object.freeze({
  ADD: 'Add',
  UPDATE: 'Update',
  REMOVE: 'Remove',
});

export const PEEntityStatus = Object.freeze({
  Active: 'Active',
  Inactive: 'Inactive',
});

export const PERulesSection = Object.freeze({
  Rules: 'rules',
  Groups: 'rule-groups',
  InheritedLogic: 'inherited-logic',
});

export const PEMIRulesSection = Object.freeze({
  Rules: 'rules',
  Premiums: 'premium groups',
});

export const EntityTypes = Object.freeze({
  Audience: 'audience',
  ClampRule: 'clamprule',
  CustomParameter: 'custom-parameter',
  Grid: 'grid',
  Product: 'product',
  Rule: 'rule',
  RuleGroup: 'rule-group',
  ValueGroup: 'valuegroup',
  Provider: 'provider',
});

export const PEObjectTypeToDisplayTypeMapping = Object.freeze({
  Audience: 'Channel',
  Product: 'Product',
  CustomValue: 'Custom Parameter',
  Tag: 'Rule Group',
  Rule: 'Rule',
  ClampRule: 'Rule',
  LockDeskRule: 'Rule',
  Grid: 'Grid',
  EligibilityMatrix: 'EligibilityMatrix',
  LockDeskWorkflowPolicy: 'Lock Policy',
  MortgageInsuranceRule: 'MI Rule',
  MortgageInsuranceMultiplierRule: 'MI Rule',
  MortgageInsuranceProduct: 'MI Product',
  MortgageInsuranceProvider: 'MI Provider',
  Partnership: 'Partnership',
});

export const PEObjectTypeToRouteNameMapping = Object.freeze({
  Audience: 'configure-channel',
  Product: 'configure-product',
  CustomValue: 'configure-custom-parameter',
  Tag: 'configure-rule-group',
  Rule: 'configure-rule',
  ClampRule: 'configure-rule',
  LockDeskWorkflowPolicy: 'lock-desk-workflow-policy-detail',
  MortgageInsuranceRule: 'mortgage-insurance-rate-card-configure-rule',
  MortgageInsuranceMultiplierRule:
    'mortgage-insurance-rate-card-configure-rule',
  MortgageInsuranceProduct: 'mortgage-insurance-rate-card-configure-premium',
});

export const TERM_OPTIONS = Object.freeze([
  { value: terms.TERM_480, label: '40 yr' },
  { value: terms.TERM_360, label: '30 yr' },
  { value: terms.TERM_348, label: '29 yr' },
  { value: terms.TERM_336, label: '28 yr' },
  { value: terms.TERM_324, label: '27 yr' },
  { value: terms.TERM_312, label: '26 yr' },
  { value: terms.TERM_300, label: '25 yr' },
  { value: terms.TERM_288, label: '24 yr' },
  { value: terms.TERM_276, label: '23 yr' },
  { value: terms.TERM_264, label: '22 yr' },
  { value: terms.TERM_252, label: '21 yr' },
  { value: terms.TERM_240, label: '20 yr' },
  { value: terms.TERM_228, label: '19 yr' },
  { value: terms.TERM_216, label: '18 yr' },
  { value: terms.TERM_204, label: '17 yr' },
  { value: terms.TERM_192, label: '16 yr' },
  { value: terms.TERM_180, label: '15 yr' },
  { value: terms.TERM_168, label: '14 yr' },
  { value: terms.TERM_156, label: '13 yr' },
  { value: terms.TERM_144, label: '12 yr' },
  { value: terms.TERM_132, label: '11 yr' },
  { value: terms.TERM_120, label: '10 yr' },
  { value: terms.TERM_108, label: '9 yr' },
  { value: terms.TERM_96, label: '8 yr' },
  { value: terms.TERM_84, label: '7 yr' },
  { value: terms.TERM_72, label: '6 yr' },
  { value: terms.TERM_60, label: '5 yr' },
  { value: terms.TERM_48, label: '4 yr' },
  { value: terms.TERM_36, label: '3 yr' },
  { value: terms.TERM_24, label: '2 yr' },
  { value: terms.TERM_12, label: '1 yr' },
  { value: terms.TERM_9, label: '9 mo' },
  { value: terms.TERM_6, label: '6 mo' },
  { value: terms.TERM_3, label: '3 mo' },
]);

export const PERuleValueListPurpose = Object.freeze({
  None: 'None',
  Contains: 'Contains',
  NotContains: 'NotContains',
  Range: 'Range',
  Min: 'Min',
  Max: 'Max',
  Average: 'Average',
});

export const PEProductPricingMethod = Object.freeze({
  DYNAMIC: 'Dynamic',
  STATIC: 'Static',
});

export const PEPointsAndFeesLimitValueType = Object.freeze({
  Percent: 'Percent',
  DollarCalculation: 'DollarCalculation',
});

export const PERuleOperations = Object.freeze({
  Add: { operation: 'Add', text: 'add' },
  Subtract: { operation: 'Subtract', text: 'subtract' },
  Multiply: { operation: 'Multiply', text: 'multiplied by' },
  Divide: { operation: 'Divide', text: 'divided by' },
  Modulo: { operation: 'Modulo', text: 'modulo' },
  PowerOf: { operation: 'PowerOf', text: 'power of' },
  EqualTo: { operation: 'EqualTo', text: 'is' },
  UnequalTo: { operation: 'UnequalTo', text: 'is not' },
  LessThan: {
    operation: 'LessThan',
    text: 'is less than',
    dateTimeText: 'is before',
  },
  LessThanOrEqualto: {
    operation: 'LessThanOrEqualto',
    text: 'is less than or equal to',
    dateTimeText: 'is before or including',
  },
  GreaterThan: {
    operation: 'GreaterThan',
    text: 'is greater than',
    dateTimeText: 'is after',
  },
  GreaterThanOrEqualTo: {
    operation: 'GreaterThanOrEqualTo',
    text: 'is greater than or equal to',
    dateTimeText: 'is after or including',
  },
  And: { operation: 'And', text: 'and' },
  Or: { operation: 'Or', text: 'or' },
  [PERuleValueListPurpose.Contains]: {
    text: 'is one of',
    listPurpose: PERuleValueListPurpose.Contains,
  },
  [PERuleValueListPurpose.NotContains]: {
    text: 'is not one of',
    listPurpose: PERuleValueListPurpose.NotContains,
  },
  [PERuleValueListPurpose.Range]: {
    text: 'is between',
    listPurpose: PERuleValueListPurpose.Range,
  },
});

export const PERuleOperationsPerFieldType = Object.freeze({
  Integer: [
    PERuleOperations.EqualTo,
    PERuleOperations.UnequalTo,
    PERuleOperations.LessThan,
    PERuleOperations.LessThanOrEqualto,
    PERuleOperations.GreaterThan,
    PERuleOperations.GreaterThanOrEqualTo,
    PERuleOperations[PERuleValueListPurpose.Range],
  ],
  Decimal: [
    PERuleOperations.EqualTo,
    PERuleOperations.UnequalTo,
    PERuleOperations.LessThan,
    PERuleOperations.LessThanOrEqualto,
    PERuleOperations.GreaterThan,
    PERuleOperations.GreaterThanOrEqualTo,
    PERuleOperations[PERuleValueListPurpose.Range],
  ],
  DateTime: [
    PERuleOperations.EqualTo,
    PERuleOperations.UnequalTo,
    PERuleOperations.LessThan,
    PERuleOperations.LessThanOrEqualto,
    PERuleOperations.GreaterThan,
    PERuleOperations.GreaterThanOrEqualTo,
    PERuleOperations[PERuleValueListPurpose.Range],
    PERuleOperations[PERuleValueListPurpose.Contains],
  ],
  String: [PERuleOperations.EqualTo, PERuleOperations.UnequalTo],
  County: [
    PERuleOperations.EqualTo,
    PERuleOperations.UnequalTo,
    PERuleOperations[PERuleValueListPurpose.Contains],
    PERuleOperations[PERuleValueListPurpose.NotContains],
  ],
  Object: [PERuleOperations.EqualTo, PERuleOperations.UnequalTo],
  List: [
    PERuleOperations.EqualTo,
    PERuleOperations.UnequalTo,
    PERuleOperations[PERuleValueListPurpose.Contains],
    PERuleOperations[PERuleValueListPurpose.NotContains],
  ],
  Boolean: [PERuleOperations.EqualTo, PERuleOperations.UnequalTo],
  Grid: [PERuleOperations.EqualTo, PERuleOperations.UnequalTo],
  Unknown: [],
});

export const PERuleCalculationOperations = Object.freeze([
  PERuleOperations.Multiply,
  PERuleOperations.Subtract,
  PERuleOperations.Divide,
  PERuleOperations.Add,
]);

export const PERuleCategories = Object.freeze({
  Eligibility: 'Eligibility',
  Adjustment: 'Adjustment',
  LockDesk: 'LockDesk',
  Margin: 'Margin',
  Clamp: 'Clamp',
  SRP: 'SRP',
  Fee: 'Fee',
  EligibilityMatrix: 'EligibilityMatrix',
  Rounding: 'Rounding',
  Compliance: 'Compliance',
});

export const PEMIRuleCategories = Object.freeze({
  StartRate: 'MortgageInsuranceStartRate',
  RateAdjuster: 'MortgageInsuranceRateAdjuster',
  Multiplier: 'MortgageInsuranceMultiplier',
});

export const PERuleSubCategories = Object.freeze({
  None: 'None',
  Corporate: 'Corporate',
  Branch: 'Branch',
  Compensation: 'Compensation',
  Misc: 'Misc',
});

// If you change, add, or remove these values, you may need to reflect those
// changes in `CATEGORY_WHITELIST` below.
export const PERuleCategoriesAll = '*';
export const PERuleCategoriesDisplay = Object.freeze({
  [PERuleCategoriesAll]: 'All',
  [PERuleCategories.Adjustment]: 'LLPA',
  [PERuleCategories.LockDesk]: 'Lock Desk',
  [PERuleCategories.Margin]: 'Margin',
  [PERuleCategories.SRP]: 'SRP',
  [PERuleCategories.Fee]: 'Fee',
  [PERuleCategories.Eligibility]: 'Eligibility',
  [PERuleCategories.EligibilityMatrix]: 'Eligibility',
  [PERuleCategories.Clamp]: 'Min/Max',
  [PERuleCategories.Rounding]: 'Rounding',
  [PERuleCategories.Compliance]: 'Compliance',
});

export const PEMIRuleCategoriesDisplay = Object.freeze({
  [PERuleCategoriesAll]: 'All',
  [PEMIRuleCategories.StartRate]: 'Start Rate',
  [PEMIRuleCategories.RateAdjuster]: 'Rate Adjustment',
  [PEMIRuleCategories.Multiplier]: 'Multiplier',
});

export const PERuleAdjustmentsDisplayOrder = Object.freeze({
  [PERuleCategories.Margin]: 0,
  [PERuleCategories.SRP]: 1,
  [PERuleCategories.Adjustment]: 2,
  [PERuleCategories.Fee]: 3,
});

export const PEEligibilityMatrixStep = Object.freeze({
  Step1: 'Step 1',
  Step2: 'Step 2',
});

export const PERuleScenarios = Object.freeze({
  LOAN_LEVEL: 'LoanLevel',
  BASE_PRICE: 'BasePriceCalculation',
  BEFORE_BEST_EX: 'BeforeBestExCalculation',
});

export const PERuleScenariosDisplay = Object.freeze({
  [PERuleScenarios.LOAN_LEVEL]: 'Loan Scenario',
  [PERuleScenarios.BASE_PRICE]: 'Base Pricing',
  [PERuleScenarios.BEFORE_BEST_EX]: 'Before Best Ex Calculation',
});

export const PEFeePaymentParty = Object.freeze({
  BORROWER: 'Borrower',
  LENDER: 'Lender',
  THIRD_PARTY: 'ThirdParty',
});

export const PEFeePaymentPartyDisplay = Object.freeze({
  [PEFeePaymentParty.BORROWER]: 'Borrower',
  [PEFeePaymentParty.LENDER]: 'Lender',
  [PEFeePaymentParty.THIRD_PARTY]: 'Third Party',
});

export const PEEligibilityType = Object.freeze({
  Ineligible: 'ineligible',
  Eligible: 'eligible',
  Matrix: 'only eligible if the matrix conditions are met',
});

export const PEEligibilityValue = Object.freeze({
  Ineligible: '0',
  Eligible: '1',
});

export const PEVerificationMethods = Object.freeze({
  FullDocument: 1,
  BankStatement: 2,
  VOE: 3,
  AssetQualification: 4,
  DSCR: 5,
  Method1099: 6,
  CPAPAndL: 7,
});
export const PEVerificationMethodsLabels = Object.freeze({
  FullDocument: 'Full Documentation',
  BankStatement: 'Bank Statement(s)',
  VOE: 'Verification of Employment',
  AssetQualification: 'Asset Qualification',
  DSCR: 'Debt Service Coverage Ratio',
  Method1099: '1099',
  CPAPAndL: 'CPA P&L',
});
export const PEVerificationMethodsOptions = createDropdownArr(
  PEVerificationMethods,
  PEVerificationMethodsLabels,
);
export const PECreditGrade = Object.freeze({
  None: 0,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  APlus: 5,
  BMinus: 6,
  AAA: 7,
  AA: 8,
  AMinus: 9,
  BB: 10,
  BPlus: 11,
  CMinus: 12,
  BBB: 13,
  CCC: 14,
  CC: 15,
  CPlus: 16,
  DDD: 17,
  DD: 18,
  DPlus: 19,
  DMinus: 20,
});
export const PECreditGradeLabels = Object.freeze({
  None: '',
  AAA: 'AAA',
  AA: 'AA',
  APlus: 'A+',
  A: 'A',
  AMinus: 'A-',
  BBB: 'BBB',
  BB: 'BB',
  BPlus: 'B+',
  B: 'B',
  BMinus: 'B-',
  CCC: 'CCC',
  CC: 'CC',
  CPlus: 'C+',
  C: 'C',
  CMinus: 'C-',
  DDD: 'DDD',
  DD: 'DD',
  DPlus: 'D+',
  D: 'D',
  DMinus: 'D-',
});
export const PECreditGradeOptions = createDropdownArr(
  PECreditGrade,
  PECreditGradeLabels,
);

export const PEInvestorExperienceOptions = Object.freeze({
  NotApplicable: 0,
  ExperiencedInvestor: 1,
  FirstTimeInvestor: 2,
});
export const PEInvestorExperienceLabels = Object.freeze({
  NotApplicable: 'N/A',
  ExperiencedInvestor: 'Experienced Investor',
  FirstTimeInvestor: 'First Time Investor',
});
export const INVESTOR_EXPERIENCE_OPTIONS = createDropdownArr(
  PEInvestorExperienceOptions,
  PEInvestorExperienceLabels,
);

export const PECitizenshipValues = Object.freeze({
  None: 0,
  ForeignNational: 1,
  NonPermanentResidentAlien: 2,
  PermanentResidentAlien: 3,
  USCitizen: 4,
  USCitizenAbroad: 5,
});
export const PECitizenshipLabels = Object.freeze({
  None: 'None',
  ForeignNational: 'Foreign National',
  NonPermanentResidentAlien: 'Non-Permanent Resident Alien',
  PermanentResidentAlien: 'Permanent Resident Alien',
  USCitizen: 'US Citizen',
  USCitizenAbroad: 'US Citizen Abroad',
});
export const PECitizenshipOptions = createDropdownArr(
  PECitizenshipValues,
  PECitizenshipLabels,
);
export const PEDocumentationVerificationValues = Object.freeze({
  None: 0,
  Stated: 1,
  Verified: 2,
});
export const PEDocumentationVerificationLabels = Object.freeze({
  None: 'None',
  Stated: 'Stated',
  Verified: 'Verifed',
});
export const PEDocumentationVerification = createDropdownArr(
  PEDocumentationVerificationValues,
  PEDocumentationVerificationLabels,
);

export const occupancyCodesSelect = Object.freeze(
  Object.keys(occupancyCodes).map(k => ({ id: k, text: occupancyCodes[k] })),
);

export const propertyCodesSelect = Object.freeze(
  Object.keys(propertyCodes).map(k => ({ id: k, text: propertyCodes[k] })),
);

export const propertyAttachmentCodesSelect = Object.freeze(
  Object.keys(propertyAttachmentCodes).map(k => ({
    id: k,
    text: propertyAttachmentCodes[k],
  })),
);

export const refinanceTypesSelect = Object.freeze(
  Object.keys(refinanceTypes).map(k => ({ id: refinanceTypes[k], text: k })),
);

const orderedStateNames = Object.keys(STATES_WITH_TERRITORIES);
orderedStateNames.sort((stateX, stateY) => {
  const isStateXUSTerritory = stateX in US_TERRITORIES;
  const isStateYUSTerritory = stateY in US_TERRITORIES;
  if (isStateXUSTerritory && !isStateYUSTerritory) {
    return 1;
  }
  if (!isStateXUSTerritory && isStateYUSTerritory) {
    return -1;
  }
  return stateX < stateY ? -1 : 1;
});
export const statesSelect = Object.freeze(
  orderedStateNames.map(k => ({
    id: STATES_WITH_TERRITORIES[k]?.abbrev,
    text: k,
  })),
);

export const streamlineRefinanceTypes = {
  NONE: 'None',
  NO_CASHOUT_STREAMLINED_REFINANCE: 'NoCashoutStreamlinedRefinance',
  NO_CASHOUT_FHA_STREAMLINED_REFINANCE: 'NoCashoutFHAStreamlinedRefinance',
};

export const PeStreamlineRefinanceValues = Object.freeze({
  None: 0,
  NoCashoutStreamlinedRefinance: 1,
  NoCashoutFHAStreamlinedRefinance: 2,
});
export const PeStreamlineRefinanceLabels = Object.freeze({
  None: 'None',
  NoCashoutStreamlinedRefinance: 'No Cashout Streamlined Refinance',
  NoCashoutFHAStreamlinedRefinance: 'No Cashout FHA Streamlined Refinance',
});
export const PEStreamlineRefinanceTypes = createDropdownArr(
  PeStreamlineRefinanceValues,
  PeStreamlineRefinanceLabels,
);

// hide refinance - it is only being kept for backwards compatability
export const loanPurposeSelect = Object.freeze(
  Object.keys(loanPurposeTypes)
    .filter(item => item != 'Refinance')
    .map(k => ({ id: loanPurposeTypes[k], text: k })),
);

export const PEMonthsOptions = Object.freeze([
  { id: 0, text: '0' },
  { id: 12, text: '1' },
  { id: 24, text: '2' },
  { id: 36, text: '3' },
  { id: 48, text: '4' },
  { id: 60, text: '5' },
]);

export const PEPrepaymentPSValues = Object.freeze({
  None: 0,
  PPS_1: 1,
  PPS_1_1_1: 2,
  PPS_2_1: 3,
  PPS_2_2: 4,
  PPS_3_6_20: 5,
  PPS_3_6: 6,
  PPS_3_2_1: 7,
  PPS_3_3_3: 8,
  PPS_4_3_2_1: 9,
  PPS_4_4_4_4: 10,
  PPS_5: 11,
  PPS_5_4_3_2_1: 12,
  PPS_5_5: 13,
  PPS_5_5_4_4_3_2_1: 14,
  PPS_5_5_5: 15,
  PPS_5_5_5_5: 16,
  PPS_5_5_5_5_5: 17,
});
export const PEPrepaymentPSLabels = Object.freeze({
  None: 'No Prepay',
  PPS_1: '1',
  PPS_1_1_1: '1/1/1',
  PPS_2_1: '2/1',
  PPS_2_2: '2/2',
  PPS_3_6_20: '3 Yr 6 Mo Int >20% paydown',
  PPS_3_6: '3 Yr 6 Months of Interest',
  PPS_3_2_1: '3/2/1',
  PPS_3_3_3: '3/3/3',
  PPS_4_3_2_1: '4/3/2/1',
  PPS_4_4_4_4: '4/4/4/4',
  PPS_5: '5',
  PPS_5_4_3_2_1: '5/4/3/2/1',
  PPS_5_5: '5/5',
  PPS_5_5_4_4_3_2_1: '5/5/4/4/3/2/1',
  PPS_5_5_5: '5/5/5',
  PPS_5_5_5_5: '5/5/5/5',
  PPS_5_5_5_5_5: '5/5/5/5/5',
});
export const PEPrepaymentPenaltyStructureOptions = createDropdownArr(
  PEPrepaymentPSValues,
  PEPrepaymentPSLabels,
);

export const PEPrepaymentPSTypeValues = Object.freeze({
  NoPrepay: 1,
  Fixed: 2,
  Declining: 3,
  SixMonthsInterest: 4,
});
export const PEPrepaymentPSTypeLabels = Object.freeze({
  NoPrepay: 'No Prepay',
  Fixed: 'Fixed',
  Declining: 'Declining',
  SixMonthsInterest: '6 Months Interest',
});
export const PEPrepaymentPenaltyStructureTypeOptions = createDropdownArr(
  PEPrepaymentPSTypeValues,
  PEPrepaymentPSTypeLabels,
);
// The following 3 constants are needed in order to satisfy
// certain PE Micro Service requirements related to
// Rule Boolean Equations
export const PEPropertyStateEnumName = 'Property.State';
export const PEEnumCast = '(int)';
/* eslint-disable-next-line */
export const PERuleStringEscape = '"';

export class PEInteger {}

export class PEDecimal {}

export class PEDateTime {}

export const PERuleGroupLoan = 'Loan';
export const PERuleGroupBorrower = 'Borrower';
export const PERuleGroupProperty = 'Property';
export const PERuleGroupProduct = 'Product';
export const PERuleGroupBaseRate = 'BaseRate';
export const PERuleGroupRequest = 'Request';
export const PERuleFieldsState = 'State';
export const PERuleFieldsCounty = 'County';
export const PERuleFieldsCountyFipsCode = 'CountyFipsCode';
export const PERuleFieldsCustomValue = 'CustomValue';
export const PERuleFieldsBrokerCompPlan = 'BrokerCompPlan';
export const PERuleFieldsChannel = 'AudienceId';
export const PERuleFieldUnitsKey = 'Units';
export const PERuleFieldPropertyUnitString = 'PropertyUnitString';
export const PERuleFieldApplicationDate = 'ApplicationDate';
export const PERuleFieldFHAPriorEndorsementDate = 'FHAPriorEndorsementDate';
export const PERuleFieldInitLockDateTime = 'InitialLockDateTime';
export const PERuleFieldCurrentDateTime = 'TenantCurrentDateTime';
export const PE1099Months = 'Months1099';
export const EncompassTPOConnect = 'EncompassTPOConnect';
export const PriceGroups = 'PriceGroups';
export const OrgId = 'OrgId';
export const TPO = 'TPO';
export const Broker = 'Broker';
export const Delegated = 'Delegated';
export const NonDelegated = 'NonDelegated';
export const MortgageInsurance = 'MortgageInsurance';
export const MI = 'MI';
export const LTV = 'LTV';
export const CoveragePercent = 'CoveragePercent';
export const IsSecondPiggyback = 'IsSecondPiggyback';
export const IsSecondCommunityLoan = 'IsSecondCommunityLoan';
export const IsSecondInvestorSameAsFirst = 'IsSecondInvestorSameAsFirst';

export const PERuleFieldDisplayNameMapping = Object.freeze({
  [PERuleGroupBaseRate]: 'Other',
  [`${PERuleGroupLoan}.${IsSecondPiggyback}`]: 'Is Second Piggyback',
  [`${PERuleGroupLoan}.${IsSecondCommunityLoan}`]: 'Is Second Community Loan',
  [`${PERuleGroupLoan}.${IsSecondInvestorSameAsFirst}`]:
    'Is Second Investor Same As First',
  [PERuleFieldsBrokerCompPlan]: 'Compensation',
  [`${PERuleGroupBaseRate}.Rate`]: 'NoteRate',
  [`${PERuleGroupBaseRate}.${PERuleFieldsChannel}`]: 'Channel',
  [PERuleFieldsChannel]: 'Channel',
  [`Property.${PERuleFieldPropertyUnitString}`]: PERuleFieldUnitsKey,
  [`Borrower.${PE1099Months}`]: '1099Months',
  [`${PERuleGroupRequest}.${PERuleFieldInitLockDateTime}`]:
    'Initial Lock Date/Time',
  [`${PERuleGroupRequest}.${PERuleFieldCurrentDateTime}`]: 'Current Date/Time',
  [`${PERuleGroupRequest}.${EncompassTPOConnect}.${OrgId}`]: `${TPO} ${OrgId}`,
  [`${PERuleGroupRequest}.${EncompassTPOConnect}.${PriceGroups}.${Broker}`]: `${TPO} ${Broker}`,
  [`${PERuleGroupRequest}.${EncompassTPOConnect}.${PriceGroups}.${Delegated}`]: `${TPO} ${Delegated}`,
  [`${PERuleGroupRequest}.${EncompassTPOConnect}.${PriceGroups}.${NonDelegated}`]: `${TPO} ${NonDelegated}`,
  [`${PERuleGroupRequest}.Product.MortgageInsuranceCoveragePercent`]: `${MI} ${CoveragePercent}`, // this field is calculated per product in pe3
});

export const MaxMinText = Object.freeze({
  Maximum: 'Maximum',
  Minimum: 'Minimum',
});

export const partnershipStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
});

export const PEEligibilityLimitOf = Object.freeze([
  {
    label: MaxMinText.Maximum,
    code: PERuleOperations.LessThanOrEqualto.operation,
  },
  {
    label: MaxMinText.Minimum,
    code: PERuleOperations.GreaterThanOrEqualTo.operation,
  },
]);

export const PEImpoundOptions = Object.freeze([
  { id: 0, text: 'None' },
  { id: 1, text: 'Partial' },
  { id: 2, text: 'Full' },
]);
export const PEAusValues = Object.freeze({
  None: 0,
  Manual: 1,
  DU: 2,
  LP: 3,
  Other: 4,
  NotSpecified: 5,
});
export const PEAusLabels = Object.freeze({
  None: 'None',
  Manual: 'Manual',
  DU: 'DU',
  LP: 'LP',
  Other: 'Other',
  NotSpecified: 'Not Specified',
});
const AUS_OPTIONS = createDropdownArr(PEAusValues, PEAusLabels);

// PE has 2 more values, not sure why we're not using them, not going to add them so we don't mess anything up though.
export const PELienPositionValues = Object.freeze({
  First: 1,
  Second: 2,
});
const PELienPositionLabels = Object.freeze({
  First: 'First',
  Second: 'Second',
});
const LOAN_POSITION_OPTIONS = createDropdownArr(
  PELienPositionValues,
  PELienPositionLabels,
);
const PEBrokerCompValues = Object.freeze({
  None: 0,
  Lender: 1,
  Borrower: 2,
});
const PEBrokerCompLabels = Object.freeze({
  None: 'None',
  Lender: 'Lender',
  Borrower: 'Borrower',
});
const BROKER_COMP_PAID_BY_OPTIONS = createDropdownArr(
  PEBrokerCompValues,
  PEBrokerCompLabels,
);
const PEFirstInvestorValues = Object.freeze({
  Same: true,
  Different: false,
});
export const FirstInvestorLabels = Object.freeze({
  Same: 'Same',
  Different: 'Different',
});
export const FirstInvestorOptions = createDropdownArr(
  PEFirstInvestorValues,
  FirstInvestorLabels,
);
export const PERuleFields = Object.freeze({
  Loan: {
    Amount: PEDecimal,
    AUS: AUS_OPTIONS,
    CashOutAmount: PEDecimal,
    FHATotalLoanAmount: PEDecimal,
    HELOCLineAmount: PEDecimal,
    HELOCDrawAmount: PEDecimal,
    Impounds: PEImpoundOptions,
    IsMortgageInsurancePaidByBorrower: Boolean,
    IsRelocationLoan: Boolean,
    LenderFee: PEDecimal,
    MaxOfLTVandCLTV: PEDecimal,
    MaxOfTLTVandCLTV: PEDecimal,
    MaxOfTLTVandHCLTV: PEDecimal,
    MaxOfLTVandCLTVandHCLTV: PEDecimal,
    MaxOfTLTVandCLTVandHCLTV: PEDecimal,
    Position: LOAN_POSITION_OPTIONS,
    PrepaymentPenaltyPeriodMonths: PEInteger,
    PrepaymentPenaltyStructure: PEPrepaymentPenaltyStructureOptions,
    PrepaymentPenaltyStructureType: PEPrepaymentPenaltyStructureTypeOptions,
    Purpose: loanPurposeSelect,
    // 'RefinancePurpose' NOTES:
    //        ID 1 in PE is referred to as "No Cash Out"
    //        in Exchange it is "Purchase"
    //
    //        ID 3 in PE is referred to as "Limited Cash Out"
    //        in Exchange it is "Rate term"
    RefinancePurpose: refinanceTypesSelect,
    RollLenderFee: Boolean,
    SecondAmount: PEDecimal,
    ServicerName: String,
    USDATotalLoanAmount: PEDecimal,
    VATotalLoanAmount: PEDecimal,
    VACashoutLTV: PEDecimal,
    VACashoutCLTV: PEDecimal,
    VACashoutHCLTV: PEDecimal,
    VACashoutMaxOfLTVAndCLTV: PEDecimal,
    VACashoutMaxOfLTVAndCLTVAndHCLTV: PEDecimal,
    [PERuleFieldApplicationDate]: PEDateTime,
    StreamlineRefinanceType: PEStreamlineRefinanceTypes,
    TemporaryBuydownType: PETemporaryBuydownTypeOptions,
    [PERuleFieldFHAPriorEndorsementDate]: PEDateTime,
    PropertyValue: PEDecimal,
    LTV: PEDecimal,
    TLTV: PEDecimal,
    CLTV: PEDecimal,
    HCLTV: PEDecimal,
    IsSecondPiggyback: Boolean,
    IsSecondCommunityLoan: Boolean,
    IsSecondInvestorSameAsFirst: Boolean,
  },
  Borrower: {
    FirstName: String,
    LastName: String,
    FICO: PEInteger,
    DTIRatio: PEDecimal,
    Citizenship: PECitizenshipOptions,
    IsFirstTimeHomeBuyer: Boolean,
    IsNonOccupancyBorrower: Boolean,
    IsNonOccupancyCoborrower: Boolean,
    IsSelfEmployed: Boolean,
    IncomeDocumentation: PEDocumentationVerification,
    AssetDocumentation: PEDocumentationVerification,
    EmploymentVerification: PEDocumentationVerification,
    MonthsOfReserves: PEInteger,
    LatePmt30x12: PEInteger,
    LatePmt60x12: PEInteger,
    LatePmt90x12: PEInteger,
    LatePmt120x12: PEInteger,
    LatePmt30x24: PEInteger,
    LatePmt60x24: PEInteger,
    LatePmt90x24: PEInteger,
    LatePmt120x24: PEInteger,
    DEMonthsBKC: PEInteger, // do we need this?
    DEMonthsDIL: PEInteger,
    DEMonthsShortSale: PEInteger,
    DEMonthsChargeOff: PEInteger,
    DEMonthsBKC7or11: PEInteger,
    DEMonthsBKC7: PEInteger,
    DEMonthsBKC11: PEInteger,
    DEMonthsBKC13: PEInteger,
    DEMonthsLoanMod: PEInteger,
    DEMonthsNoticeOfDefault: PEInteger,
    DEMonthsForeclosure: PEInteger,
    DEMultBK: Boolean,
    DEMultEvents: Boolean,
    VerificationMethod: PEVerificationMethodsOptions,
    [PE1099Months]: PEInteger,
    ResidualIncome: PEDecimal,
    CreditGrade: PECreditGradeOptions,
    IsNonTraditionalCredit: Boolean,
    IsGiftFunds: Boolean,
    FullDocMonths: PEInteger,
    CPAPandLMonths: PEInteger,
    BankStatementsNumberOfMonthsPersonal: PEInteger,
    BankStatementsNumberOfMonthsBusiness: PEInteger,
    VerificationOfEmploymentAmount: PEDecimal,
    AssetQualificationAmount: PEDecimal,
    DebtServiceCoverageRatio: PEDecimal,
    AssetDepletionAmount: PEDecimal,
    BankStatementExpenseMethod: [
      { id: 1, text: '2 Years' },
      { id: 2, text: '1 Year Tax Return + P&L' },
      { id: 3, text: '1 Year CPA P&L' },
    ],
    PropertiesOwned: PEInteger,
    MultipleBorrowerPairs: Boolean,
    InvestorExperience: INVESTOR_EXPERIENCE_OPTIONS,
    AnnualIncome: PEDecimal,
  },
  [PERuleGroupProduct]: {
    Name: String,
    Code: String,
    Category: loanCategoriesSelect,
    CalculatedLoanLimitClassification: loanCategoriesSelect,
    Position: [
      { id: 1, text: 'First' },
      { id: 2, text: 'Second' },
      { id: 3, text: 'HELOC' },
    ],
    Amortization: [
      { id: 1, text: 'Fixed' },
      { id: 2, text: 'ARM' },
      { id: 3, text: 'Balloon' },
      { id: 4, text: 'OptionsARM' },
    ],
    LoanTermMonths: PEInteger,
    AmortizationTermMonths: PEInteger,
    LoanType: Object.keys(loanTypes).map(k => ({ id: loanTypes[k], text: k })),
    LoanSubType: LoanSubTypesSelect,
    IsActive: Boolean,
    InterestOnlyTermMonths: PEInteger,
    ARMFixedTermMonths: PEInteger,
    ARMIndex: armIndexSelect,
    ARMMargin: PEDecimal,
    ARMFirstCap: PEDecimal,
    ARMPeriodicCap: PEDecimal,
    ARMLifetimeCap: PEDecimal,
    ARMLifetimeFloor: PEDecimal,
    ARMPeriodicFloor: PEDecimal,
    ARMResetFrequencyMonths: PEInteger,
    IsHedged: Boolean,
    IsAffordable: Boolean,
    IsBond: Boolean,
    LockPeriod: PEInteger,
  },
  [PERuleGroupProperty]: {
    EstimatedValue: PEDecimal,
    AppraisedValue: PEDecimal,
    PropertyType: propertyCodesSelect,
    PropertyAttachmentType: propertyAttachmentCodesSelect,
    Occupancy: occupancyCodesSelect,
    [PERuleFieldUnitsKey]: PEInteger,
    Stories: PEInteger,
    AddressLine1: String,
    AddressLine2: String,
    City: String,
    [PERuleFieldsState]: statesSelect,
    StateFipsCode: String,
    [PERuleFieldsCounty]: String,
    [PERuleFieldsCountyFipsCode]: String,
    ZipCode: String,
    ZipCodePlusFour: String,
    LotSizeInAcres: PEDecimal,
    IsNonWarrantableProject: Boolean,
    IsCondotel: Boolean,
    IsDecliningMarket: Boolean,
    InspectionWaiver: Boolean,
    MedianIncome: PEDecimal,
    IsHighCostCounty: Boolean,
    CensusTract: String,
    MSACode: String,
  },
  [PERuleFieldsBrokerCompPlan]: {
    FixedAmount: PEDecimal,
    Percent: PEDecimal,
    MinAmount: PEDecimal,
    MaxAmount: PEDecimal,
    CalculatedAdjustment: PEDecimal,
    CalculatedAmount: PEDecimal,
    PaidBy: BROKER_COMP_PAID_BY_OPTIONS,
  },
  [PERuleGroupBaseRate]: {
    [PERuleFieldsChannel]: {},
    Investor: String,
    LockPeriod: PEInteger,
    SourceLockPeriod: PEInteger,
    Rate: PEDecimal,
    Coupon: PEDecimal,
  },
  [PERuleGroupRequest]: {
    [PERuleFieldInitLockDateTime]: PEDateTime,
    [PERuleFieldCurrentDateTime]: PEDateTime,
    [`${EncompassTPOConnect}.${OrgId}`]: String,
    [`${EncompassTPOConnect}.${PriceGroups}.${Broker}`]: String,
    [`${EncompassTPOConnect}.${PriceGroups}.${Delegated}`]: String,
    [`${EncompassTPOConnect}.${PriceGroups}.${NonDelegated}`]: String,
    [`Product.MortgageInsuranceCoveragePercent`]: PEDecimal, // this field is calculated per product in pe3
    CountyLoanLimitYearToUse: PEInteger,
  },
});

export const STATE_FIPS = Object.freeze({
  AL: '01',
  AK: '02',
  AZ: '04',
  AR: '05',
  CA: '06',
  CO: '08',
  CT: '09',
  DE: '10',
  DC: '11',
  FL: '12',
  GA: '13',
  HI: '15',
  ID: '16',
  IL: '17',
  IN: '18',
  IA: '19',
  KS: '20',
  KY: '21',
  LA: '22',
  ME: '23',
  MD: '24',
  MA: '25',
  MI: '26',
  MN: '27',
  MS: '28',
  MO: '29',
  MT: '30',
  NE: '31',
  NV: '32',
  NH: '33',
  NJ: '34',
  NM: '35',
  NY: '36',
  NC: '37',
  ND: '38',
  OH: '39',
  OK: '40',
  OR: '41',
  PA: '42',
  RI: '44',
  SC: '45',
  SD: '46',
  TN: '47',
  TX: '48',
  UT: '49',
  VT: '50',
  VA: '51',
  WA: '53',
  WV: '54',
  WI: '55',
  WY: '56',
  AS: '60',
  GU: '66',
  MH: '68',
  MP: '69',
  PR: '72',
  VI: '78',
});

export const LOADER_TYPE = Object.freeze({
  CONTENT: 'content',
  LINES: 'lines',
  PARAGRAPH: 'paragraph',
  ROW: 'row',
  TABLE: 'table',
  TEXT: 'text',
});

export const TODAY_DATE_RANGE = { start: new Date(), end: new Date() };

// DEPRECATED
export const LOAN_LOCK_STATUS = Object.freeze({
  NONE: 0,
  REQUESTED: 1,
  LOCKED: 2,
  REJECTED: 3,
  EXPIRED: 4,
  CANCELLED: 5,
});

export const LOCK_RATE_STATUS = Object.freeze({
  NOT_LOCKED: 0,
  LOCKED: 1,
  LOCK_PENDING: 2,
  REPRICE_PENDING: 3,
  EXTENSION_PENDING: 4,
  CANCELLATION_PENDING: 5,
  RELOCK_PENDING: 6,
  EXPIRED: 7,
  CANCELLED: 8,
  PRICE_EXCEPTION_PENDING: 9,
  FLOAT_DOWN_PENDING: 10,
  PRODUCT_CHANGE_PENDING: 11,
  RESET_PENDING: 12,
});

export const LOCK_REQUEST_STATUS = Object.freeze({
  NONE: 0,
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
  CANCELLED: 4,
  BUNDLE_PENDING: 5,
});

export const LOCK_RATE_ACTIONS = Object.freeze({
  LOCK: 0,
  REPRICE: 1,
  EXTENSION: 2,
  RELOCK: 3,
  CANCEL: 4,
  PRICE_EXCEPTION: 5,
  FLOAT_DOWN: 6,
  FLOAT_REQUEST: 7,
  PRODUCT_CHANGE: 8,
  RESET: 9,
});

export const LOS_WRITEBACK_QUEUE_ITEM_STATUS = Object.freeze({
  PENDING: 0,
  PROCESSING: 1,
  RETRYING: 2,
  SUCCESS: 3,
  FAILED: 4,
  CANCELLED: 5,
  AWAITING_APPROVAL: 6,
  DENIED: 7,
});

export const ADJUSTMENT_KIND = Object.freeze({
  ADJUSTMENT: 0,
  EXTENSION_FEE: 1,
  RELOCK_FEE: 2,
});

export const LOAN_DETAIL_TABLE_FIELD_CHOICE = Object.freeze({
  SPOT_BIDS: 'SpotBids',
  FORWARD_BIDS: 'ForwardBids',
});

export const STRING_POOL = {
  dash: '--',
};

export const CONNECTED_SYSTEMS_DISPLAY_NAMES = {
  Encompass: 'Encompass by Ellie Mae',
  MGIC: 'MGIC',
};

export const CONNECTED_SYSTEMS_DISPLAY_TYPES = {
  'Loan Origination': 'LOS',
  'Mortgage Insurance Quoting': 'MI',
  'Loan Point of Sales': 'POS',
};

export const CONNECTED_SYSTEMS_TYPES = {
  Los: 'Loan Origination',
  MiQuote: 'Mortgage Insurance Quoting',
  InvestorApi: 'Investor API',
  RateTable: 'Rate Table',
  LoanPos: 'Loan Point of Sales',
};

export const AUTOMATED_UNDERWRITING_SYSTEM = Object.freeze({
  None: 0,
  Manual: 1,
  DU: 2,
  LP: 3,
  Other: 4,
  NotSpecified: 5,
});

export const LIEN_POSITIONS = Object.freeze({
  First: 1,
  Second: 2,
  HELOC: 3,
});

export const deliveryStatus = Object.freeze({
  unspecified: 'UNSPECIFIED',
  ready: 'READY',
  inProgress: 'IN_PROGRESS',
  delivered: 'DELIVERED',
  failed: 'FAILED',
});

export const FILE_UPLOAD_STATUS_MAPPING = Object.freeze({
  UNSPECIFIED: 'Unspecified',
  SUCCESS: 'Successful',
  FAILED: 'Failed',
  PROCESSING: 'In Process',
  ARCHIVED: 'Archived',
});

export const FILE_UPLOAD_TYPE = Object.freeze({
  UNSPECIFIED: 'UNSPECIFIED',
  BULK_LOCK_UPDATE: 'BULK_LOCK_UPDATE',
});

export const PERMISSIONS = {
  can_access_exchange: 'Exchange',
  can_access_delivery: 'Delivery',
  can_access_pe: 'Pricing Engine',
};

export const ADJUSTMENT_UNITS = {
  BASISPOINTS_PRICE: 'price in percent',
  BASISPOINTS_RATE: 'rate in percent',
  DOLLARS: 'price in dollars',
  PERCENT: 'percent',
};

export const RULE_TARGET = {
  PRICE: 'Price',
  RATE: 'Rate',
  ARM_MARGIN: 'ARMMargin',
};

export const ADJUSTMENT_TYPE = Object.freeze({
  PRICE: 0,
  RATE: 1,
  PROFITABILITY: 2,
});

export const ADJUSTMENT_NAME_MATCH = {
  EXCEPTION: 'Exception (',
};

export const OPERATION_STATUS = Object.freeze({
  ACTIVE: 'ACTIVE',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
});

export const NOTIFICATION_PREFERENCES = {
  BID_CANCELLED: 'bid_cancelled',
  BID_COUNTERED: 'bid_countered',
  BID_CREATED: 'bid_created',
  BID_REACTIVATED: 'bid_reactivated',
  BID_UPDATED: 'bid_updated',
  BID_UPLOAD: 'bid_upload',
  FLOAT_REQUEST: 'float_request',
  LOAN_SALE: 'loan_sale',
  LOAN_UPLOAD: 'loan_upload',
  LOCK_CANCELLATION: 'lock_cancellation',
  LOCK_DESK_VOLUME_DAILY: 'lock_desk_volume_daily',
  LOCK_DESK_VOLUME_HOURLY: 'lock_desk_volume_hourly',
  LOCK_DESK_VOLUME_MONTHLY: 'lock_desk_volume_monthly',
  LOCK_DESK_VOLUME_WEEKLY: 'lock_desk_volume_weekly',
  PIPELINE_MONITORING_REPORT: 'pipeline_monitoring_report',
  LOCK_EXTENSION_REQUEST: 'lock_extension_request',
  LOCK_REQUEST: 'lock_request',
  MEMBERSHIP_INVITE: 'membership_invite',
  MEMBERSHIP_UPDATE: 'membership_update',
  PARTNERSHIP_INVITE: 'partnership_invite',
  PARTNERSHIP_UPDATE: 'partnership_update',
  POOLS_COMMITTING: 'pools_committing',
  PRICE_EXCEPTION_REQUEST: 'price_exception_request',
  RELOCK_REQUEST: 'relock_request',
  LOCK_RESET_REQUEST: 'lock_reset_request',
  REPRICE_REQUEST: 'reprice_request',
  REPRICE_REQUIRED: 'reprice_required',
  FLOAT_DOWN_REQUEST: 'float_down_request',
  EMAIL_ATTACHMENTS: 'include_email_attachments',
  RATE_SHEET_INGESTION: 'rate_sheet_ingestion',
  PRODUCT_CHANGE: 'product_change_request',
  REQUEST_IN_REVIEW_CANCELLED: 'request_in_review_cancelled',
  LOAN_OFFICER_LOCK_REQUEST_SUBMITTED: 'loan_officer_lock_request_submitted',
  LOAN_OFFICER_LOCK_REQUEST_REVIEWED: 'loan_officer_lock_request_reviewed',
  LOAN_OFFICER_REPRICE_SUBMITTED: 'loan_officer_reprice_submitted',
  LOAN_OFFICER_REPRICE_REVIEWED: 'loan_officer_reprice_reviewed',
  LOAN_OFFICER_LOCK_EXTENSION_SUBMITTED:
    'loan_officer_lock_extension_submitted',
  LOAN_OFFICER_LOCK_EXTENSION_REVIEWED: 'loan_officer_lock_extension_reviewed',
  LOAN_OFFICER_RELOCK_SUBMITTED: 'loan_officer_relock_submitted',
  LOAN_OFFICER_RELOCK_REVIEWED: 'loan_officer_relock_reviewed',
  LOAN_OFFICER_LOCK_RESET_REVIEWED: 'loan_officer_lock_reset_reviewed',
  LOAN_OFFICER_CANCELLATION_SUBMITTED: 'loan_officer_cancellation_submitted',
  LOAN_OFFICER_CANCELLATION_REVIEWED: 'loan_officer_cancellation_reviewed',
  LOAN_OFFICER_PRICE_EXCEPTION_SUBMITTED:
    'loan_officer_price_exception_submitted',
  LOAN_OFFICER_PRICE_EXCEPTION_REVIEWED:
    'loan_officer_price_exception_reviewed',
  LOAN_OFFICER_FLOAT_DOWN_SUBMITTED: 'loan_officer_float_down_submitted',
  LOAN_OFFICER_FLOAT_DOWN_REVIEWED: 'loan_officer_float_down_reviewed',
  LOAN_OFFICER_FLOAT_REGISTER_SUBMITTED:
    'loan_officer_float_register_submitted',
  LOAN_OFFICER_FLOAT_REGISTER_REVIEWED: 'loan_officer_float_register_reviewed',
  LOAN_OFFICER_PRODUCT_CHANGE_SUBMITTED:
    'loan_officer_product_change_submitted',
  LOAN_OFFICER_PRODUCT_CHANGE_REVIEWED: 'loan_officer_product_change_reviewed',
  LOAN_OFFICER_LOCK_EXPIRING: 'loan_officer_lock_expiring',
};

export const ROLE_TYPES = {
  PE_CAPITAL_MARKETS: 0,
  PE_LOCK_DESK: 1,
  PE_LOAN_OFFICER: 2,
  PE_DEVELOPER: 3,
  EXCHANGE: 4,
  PE_APPROVER: 5,
};

export const EXCEPTION_TYPES = Object.freeze({
  BRANCH: 0,
  CORPORATE: 1,
});

export const FORMATTED_EXCEPTION_TYPES = Object.freeze({
  [EXCEPTION_TYPES.BRANCH]: 'Branch',
  [EXCEPTION_TYPES.CORPORATE]: 'Corporate',
});

export const CUSTOM_PARAM_CATEGORY = Object.freeze({
  CUSTOM: 'Custom',
  BORROWER: 'Borrower',
  LOAN: 'Loan',
  PROPERTY: 'Property',
  PRODUCT: 'Product',
});

export const CUSTOM_PARAM_CATEGORY_NAME = Object.freeze({
  [CUSTOM_PARAM_CATEGORY.CUSTOM]: 'Other',
  [CUSTOM_PARAM_CATEGORY.BORROWER]: 'Borrower',
  [CUSTOM_PARAM_CATEGORY.LOAN]: 'Loan',
  [CUSTOM_PARAM_CATEGORY.PROPERTY]: 'Property',
  [CUSTOM_PARAM_CATEGORY.PRODUCT]: 'Product',
});

export const CUSTOM_PARAMETER_TYPES = Object.freeze({
  STRING: 'StringType',
  BOOLEAN: 'BooleanType',
  DECIMAL: 'DecimalType',
  INTEGER: 'IntegerType',
  DATETIME: 'DateTimeType',
  ENUM: 'EnumType',
});

export const CUSTOM_PARAMETER_TYPES_CONFIG = Object.freeze({
  [CUSTOM_PARAMETER_TYPES.STRING]: {
    name: 'String',
    type: String,
    peClass: null,
  },
  [CUSTOM_PARAMETER_TYPES.BOOLEAN]: {
    name: 'Boolean',
    type: Boolean,
    peClass: 'bool',
  },
  [CUSTOM_PARAMETER_TYPES.DECIMAL]: {
    name: 'Decimal',
    type: PEDecimal,
    peClass: 'decimal',
  },
  [CUSTOM_PARAMETER_TYPES.INTEGER]: {
    name: 'Integer',
    type: PEInteger,
    peClass: 'int',
  },
  [CUSTOM_PARAMETER_TYPES.DATETIME]: {
    name: 'DateTime',
    type: PEDateTime,
    peClass: null,
  },
  [CUSTOM_PARAMETER_TYPES.ENUM]: {
    name: 'Enum',
    type: {},
    peClass: null,
  },
});

export const COMMIT_WARNING_HEADER = 'Cannot Commit';
export const COMMIT_ERROR_HEADER = 'Commitment Failure';
export const COMMIT_ERROR_MESSAGE =
  'Failed to commit pools. Either the market is closed or an error occurred. Contact PollyEx for assistance.';

export const WORKFLOW_TYPES = {
  CANCEL: 'CANCEL',
  EXTENSION: 'EXTENSION',
  LOCK: 'LOCK',
  PRICE_EXCEPTION: 'PRICE_EXCEPTION',
  RELOCK: 'RELOCK',
  REPRICE: 'REPRICE',
  PRODUCT_CHANGE: 'PRODUCT_CHANGE',
  FLOAT_REQUEST: 'FLOAT_REQUEST',
  RESET: 'RESET',
  RENEGOTIATION: 'RENEGOTIATION',
  // why is float down not here?
};

export const WORKFLOW_MODES = Object.freeze({
  EXTENSION: 'EXTENSION',
  EXCEPTION: 'EXCEPTION',
  RELOCK: 'RELOCK',
  FLOAT_DOWN: 'FLOAT_DOWN',
  CANCEL: 'CANCEL',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  REPRICE: 'REPRICE',
  PRODUCT_CHANGE: 'PRODUCT_CHANGE',
  REFRESH: 'REFRESH',
  FORCE_REPRICE: 'FORCE_REPRICE',
  LOCK: 'LOCK',
  FLOAT_REQUEST: 'FLOAT_REQUEST',
  RESET: 'RESET',
  RENEGOTIATION: 'RENEGOTIATION',
});

export const pricerModesToWorkflowModes = Object.freeze({
  lock: WORKFLOW_MODES.LOCK,
  reprice: WORKFLOW_MODES.REPRICE,
  relock: WORKFLOW_MODES.RELOCK,
  floatDown: WORKFLOW_MODES.FLOAT_DOWN,
  productChange: WORKFLOW_MODES.PRODUCT_CHANGE,
  renegotiation: WORKFLOW_MODES.RENEGOTIATION,
});

export const WORKFLOW_STEPS = Object.freeze({
  PENDING: 'PENDING',
  CONFIRM: 'CONFIRM',
  SUBMITTED: 'SUBMITTED',
  SELECT: 'SELECT',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
  INVALID: 'INVALID',
  CONFIRMED: 'CONFIRMED',
});

export const LOCK_DESK_WORKFLOW_POLICY_TYPES = {
  [WORKFLOW_TYPES.CANCEL]: 'Cancel',
  [WORKFLOW_TYPES.EXTENSION]: 'Extension',
  FLOAT_DOWN: 'FloatDown',
  [WORKFLOW_TYPES.LOCK]: 'Lock',
  [WORKFLOW_TYPES.PRICE_EXCEPTION]: 'PriceException',
  [WORKFLOW_MODES.EXCEPTION]: 'PriceException',
  [WORKFLOW_TYPES.RELOCK]: 'Relock',
  [WORKFLOW_TYPES.REPRICE]: 'Reprice',
  [WORKFLOW_TYPES.PRODUCT_CHANGE]: 'ProductChange',
  [WORKFLOW_TYPES.FLOAT_REQUEST]: 'FloatRequest',
  [WORKFLOW_TYPES.RESET]: 'Reset',
  [WORKFLOW_TYPES.RENEGOTIATION]: 'Renegotiation',
};

export const LOCK_DESK_WORKFLOW_TABS = {
  [WORKFLOW_TYPES.CANCEL]: 'lock-cancellations',
  [WORKFLOW_TYPES.EXTENSION]: 'lock-extensions',
  FLOAT_DOWN: 'float-downs',
  [WORKFLOW_TYPES.LOCK]: 'initial-locks',
  [WORKFLOW_TYPES.PRICE_EXCEPTION]: 'price-exceptions',
  [WORKFLOW_TYPES.RELOCK]: 'relocks',
  [WORKFLOW_TYPES.REPRICE]: 'reprices',
  [WORKFLOW_TYPES.PRODUCT_CHANGE]: 'product-changes',
  [WORKFLOW_TYPES.RESET]: 'lock-resets',
};

export const LOCK_DESK_WORKFLOW_TAB_LABELS = Object.freeze({
  [WORKFLOW_TYPES.CANCEL]: 'Lock Cancellations',
  [WORKFLOW_TYPES.EXTENSION]: 'Lock Extensions',
  FLOAT_DOWN: 'Float Downs',
  [WORKFLOW_TYPES.LOCK]: 'Locks',
  [WORKFLOW_TYPES.PRICE_EXCEPTION]: 'Price Exceptions',
  [WORKFLOW_TYPES.RELOCK]: 'Relocks',
  [WORKFLOW_TYPES.REPRICE]: 'Reprices',
  [WORKFLOW_TYPES.PRODUCT_CHANGE]: 'Product Changes',
  [WORKFLOW_TYPES.RESET]: 'Lock Resets',
});

export const LOCK_DESK_TABS_TO_TYPES = (() => {
  const mapping = {};
  for (const tab in LOCK_DESK_WORKFLOW_TABS) {
    mapping[LOCK_DESK_WORKFLOW_TABS[tab]] = tab;
  }
  return mapping;
})();

export const WORKFLOW_POLICY_DISPLAY = {
  Cancel: 'Lock Cancellation',
  Extension: 'Lock Extension',
  FloatDown: 'Float Down',
  FloatRequest: 'Float Request',
  Lock: 'Lock',
  PriceException: 'Price Exception',
  Relock: 'Relock',
  Reprice: 'Reprice',
  ProductChange: 'Product Change',
  Reset: 'Lock Reset',
  Renegotiation: 'Renegotiation',
};

export const WORKFLOW_POLICY_LOCK_DESK_TRANSFORMATION = {
  [WORKFLOW_POLICY_DISPLAY.Lock]: 'Lock Request',
  [WORKFLOW_POLICY_DISPLAY.Cancel]: 'Lock Cancellation Request',
  [WORKFLOW_POLICY_DISPLAY.FloatDown]: 'Float Down Request',
  [WORKFLOW_POLICY_DISPLAY.FloatRequest]: 'Lock after Float Request',
  [WORKFLOW_POLICY_DISPLAY.Extension]: 'Lock after Extension',
  [WORKFLOW_POLICY_DISPLAY.PriceException]: 'Lock after Exception',
  [WORKFLOW_POLICY_DISPLAY.Relock]: 'Lock after Relock',
  [WORKFLOW_POLICY_DISPLAY.Reprice]: 'Lock after Reprice',
  [WORKFLOW_POLICY_DISPLAY.ProductChange]: 'Lock after Product Change',
  [WORKFLOW_POLICY_DISPLAY.Reset]: 'Lock Reset Request',
};

export const PRICER_MODE_TO_WORKFLOW_CONVERSION = {
  [WORKFLOW_POLICY_DISPLAY.Lock.toLowerCase()]: pricerModes.lock,
  // for legacy workflow modes
  'initial lock': pricerModes.lock,
};

export const WORKFLOW_POLICY_USAGE_VALIDATION = {
  Extension: {
    min: 0,
  },
  Relock: {
    min: 0,
  },
};

export const LOCK_DESK_WORKFLOW_SETTINGS = {
  CANCEL: 'has_cancellation_enabled',
  EXTENSION: 'has_extensions_enabled',
  FLOAT_DOWN: 'has_float_downs_enabled',
  FLOAT_REQUEST: 'has_float_request_enabled',
  LOCK: 'has_lock_requests_enabled',
  PRICE_EXCEPTION: 'has_lock_exceptions_enabled',
  RELOCK: 'has_relock_enabled',
  REPRICE: 'has_reprice_enabled',
  PRODUCT_CHANGE: 'has_product_change_enabled',
  RENEGOTIATION: 'has_renegotiation_enabled',
};

export const LOCK_DESK_WORKFLOW_SETTINGS_VALID_STATES = {
  CANCEL: 'has_valid_cancellation_default_policy',
  EXTENSION: 'has_valid_extension_default_policy',
  FLOAT_DOWN: 'has_valid_float_down_default_policy',
  FLOAT_REQUEST: 'has_valid_float_request_default_policy',
  LOCK: 'has_valid_lock_requests_default_policy',
  PRICE_EXCEPTION: 'has_valid_lock_exception_default_policy',
  RELOCK: 'has_valid_relock_default_policy',
  REPRICE: 'has_valid_reprice_default_policy',
  PRODUCT_CHANGE: 'has_valid_product_change_default_policy',
  RENEGOTIATION: 'has_valid_renegotiation_default_policy',
};

export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';

// max length in DB is 2048. Max length on FE can be increased but only
// up to ~2020 as some comments are appended with an exception reason
export const MAX_COMMENT_LENGTH = 500;

export const PriceRoundingType = Object.freeze({
  Down: 'NearestDown',
  Up: 'NearestUp',
  Par: 'Par',
  ParAndStack: 'ParAndStack',
  ParAndRange: 'ParAndRange',
});

export const PriceRoundingTypeDisplay = Object.freeze({
  [PriceRoundingType.Down]: 'down',
  [PriceRoundingType.Up]: 'up',
});

export const PriceRoundingTarget = Object.freeze({
  BasePrice: 'BasePrice',
  FinalPrice: 'FinalPrice',
  Par: 'Par',
  ParAndStack: 'ParAndStack',
  ParAndRange: 'ParAndRange',
});

export const PriceRoundingTargetDisplay = Object.freeze({
  [PriceRoundingTarget.BasePrice]: 'base price',
  [PriceRoundingTarget.FinalPrice]: 'final price',
  [PriceRoundingTarget.Par]: 'par price',
  [PriceRoundingTarget.ParAndStack]: 'par price',
  [PriceRoundingTarget.ParAndRange]: 'par price',
});

export const PriceRoundingAccuracy = Object.freeze({
  Value8: 8,
  Value4: 4,
});

export const PriceRoundingAccuracyDisplay = Object.freeze({
  [PriceRoundingAccuracy.Value8]: '1/8th',
  [PriceRoundingAccuracy.Value4]: '1/4th',
});

export const ORG_USER_PERMISSIONS = Object.freeze({
  DELIVERY: 'can_access_delivery',
  DEV_TOOLS: 'can_access_dev_tools',
  EXCHANGE: 'can_access_exchange',
  LOCK_DESK: 'can_access_lock_desk',
  PE: 'can_access_pe',
  MI_RATE_CARD: 'can_access_mi_rate_card',
  PUBLISH_PRICING: 'can_access_publish_pricing',
  LOAN_PIPELINE: 'can_access_loan_pipeline',
  LOCK_UPDATER: 'can_access_lock_updater',
  IS_ADMIN: 'is_admin',
  IS_BUYER: 'is_buyer',
  IS_SELLER: 'is_seller',
  HAS_SELL_SIDE_ANALYSIS: 'has_sell_side_analysis_enabled',
});

export const LOCK_DESK_WORKFLOW_FEE_TYPES = Object.freeze({
  FEE_PER_DAY: 'FeePerDay',
  FLAT_FEE: 'FlatFee',
  NONE: 'None',
  SET_OF_FEES: 'SetOfFees',
});
export const pePurposeTypesValues = Object.freeze({
  None: 0,
  Purchase: 1,
  Refinance: 2,
  Construction: 3,
  ConstructionPerm: 4,
  Other: 5,
  NoCashOutRefinance: 6,
  CashOutRefinance: 7,
});
export const pePurposeTypesToStr = Object.freeze({
  None: 'Unspecified',
  Purchase: 'Purchase',
  Refinance: 'Refinance',
  Construction: 'Construction',
  ConstructionPerm: 'Construction Permit',
  Other: 'Other',
  NoCashOutRefinance: 'No Cash-Out Refi',
  CashOutRefinance: 'Cash-Out Refi',
});

export const peRefinancePurposeTypesToId = Object.freeze({
  None: 0,
  NoCashOut: 1,
  CashOut: 2,
  LimitedCashOut: 3,
  HomeImprovement: 4,
  DebtConsolidation: 5,
  Other: 6,
});

export const peOccupancyTypesValues = Object.freeze({
  PrimaryResidence: 1,
  SecondHome: 2,
  InvestmentProperty: 3,
});
export const peOccupancyTypesToStr = Object.freeze({
  PrimaryResidence: 'Primary',
  SecondHome: 'Secondary',
  InvestmentProperty: 'Investment',
});

export const pePropertyTypesValues = Object.freeze({
  None: 0,
  SFR: 1,
  Condominium: 2,
  PUD: 3,
  Mobile: 5,
  TwoFourUnit: 6,
  Cooperative: 7,
  Townhome: 8,
  Multifamily: 9,
  Commercial: 10,
  MixedUse: 11,
  Farm: 12,
  HomeAndBusiness: 13,
  Land: 14,
  ManufacturedSingleWide: 15,
  ManufacturedDoubleWide: 16,
});
export const pePropertyTypesToStr = Object.freeze({
  None: 'Unspecified',
  SFR: 'Single Family',
  Condominium: 'Condo',
  PUD: 'PUD',
  Mobile: 'Mobile',
  TwoFourUnit: 'Multi Unit',
  Cooperative: 'Co-op',
  Townhome: 'Townhouse',
  Multifamily: 'Multi Family',
  Commercial: 'Commercial',
  MixedUse: 'Mixed Use',
  Farm: 'Farm',
  HomeAndBusiness: 'Home Business',
  Land: 'Home Land',
  ManufacturedSingleWide: 'Manufactured Single',
  ManufacturedDoubleWide: 'Manufactured Double',
});

export const pePropertyAttachmentTypesToStr = Object.freeze({
  Unspecified: 'Unspecified',
  Detached: 'Detached',
  Attached: 'Attached',
});

export const ROLES = Object.freeze({
  PE_CAPITAL_MARKETS: 0,
  PE_LOCK_DESK: 1,
  PE_REQUESTOR: 2,
  PE_DEVELOPER: 3,
  EXCHANGE: 4,
  PE_APPROVER: 5,
});

export const NOTES_ROLES = Object.freeze({
  PE_REQUESTOR: 'Requestor',
  PE_APPROVER: 'Approver',
  LOCK_DESK: 'Lock Desk',
  CAPITAL_MARKETS: 'Capital Markets',
  ADMIN: 'Admin',
});

export const ROLES_TO_NOTE_ROLES = Object.freeze({
  [ROLE_TYPES.EXCHANGE]: 'PE_APPROVER',
  [ROLE_TYPES.PE_APPROVER]: 'PE_APPROVER',
  [ROLE_TYPES.PE_DEVELOPER]: 'PE_APPROVER',
  [ROLE_TYPES.PE_CAPITAL_MARKETS]: 'CAPITAL_MARKETS',
  [ROLE_TYPES.PE_LOAN_OFFICER]: 'PE_REQUESTOR',
  [ROLE_TYPES.PE_LOCK_DESK]: 'LOCK_DESK',
  ADMIN: 'ADMIN',
});

export const ROLES_PRODUCT = Object.freeze({
  PRICE_ENGINE: [
    ROLES.PE_CAPITAL_MARKETS,
    ROLES.PE_LOCK_DESK,
    ROLES.PE_REQUESTOR,
    ROLES.PE_APPROVER,
  ],
  EXCHANGE: [ROLES.EXCHANGE],
});

export const ROLE_ACCESS_FLAG = Object.freeze({
  LOAN_OFFICER: 'has_loan_officer_enabled',
  EXCEPTION_MANAGEMENT: 'has_price_exception_management_enabled',
});

export const WIDGET_TYPES = Object.freeze({
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
});

export const MEMBER_PRODUCT_ROLES = Object.freeze({
  PRICE_ENGINE: {
    title: 'Price Engine',
    orgPermission: ORG_USER_PERMISSIONS.PE,
    defaultRole: ROLES.PE_CAPITAL_MARKETS,
    options: [
      {
        permission: 'Capital Markets',
        value: ROLES.PE_CAPITAL_MARKETS,
        description: 'Access to all areas of the Pricing Engine.',
        widget: {
          type: WIDGET_TYPES.RADIO,
        },
      },
      {
        permission: 'Lock Desk',
        value: ROLES.PE_LOCK_DESK,
        description: 'Access to Lock Desk, Loan Pipeline, and Loan Scenarios.',
        widget: {
          type: WIDGET_TYPES.RADIO,
        },
      },
      {
        permission: 'Loan Officer',
        featureFlag: ROLE_ACCESS_FLAG.LOAN_OFFICER,
        value: ROLES.PE_REQUESTOR,
        description:
          'Can belong in an Exception Requestor Group. Access to Loan Pipeline Scenarios.',
        widget: {
          type: WIDGET_TYPES.RADIO,
        },
      },
      {
        permission: 'Exception Approver',
        featureFlag: ROLE_ACCESS_FLAG.EXCEPTION_MANAGEMENT,
        value: ROLES.PE_APPROVER,
        description:
          'Can be in an Exception Reviewal Group and review Exceptions in the Lock Desk.',
        widget: {
          type: WIDGET_TYPES.CHECKBOX,
        },
      },
    ],
  },

  EXCHANGE: {
    title: 'Exchange',
    orgPermission: ORG_USER_PERMISSIONS.EXCHANGE,
    defaultRole: ROLES.EXCHANGE,
    options: [
      {
        permission: 'Exchange',
        value: ROLES.EXCHANGE,
        description: 'Has access to Exchange',
        widget: {
          type: WIDGET_TYPES.RADIO,
        },
      },
    ],
  },
});

export const MEMBER_INVITE_STEPS = {
  [ROLES.PE_REQUESTOR]: 1,
};

export const LOAN_OPTION_TYPES = Object.freeze({
  FINANCE: 'Finance',
  PAID_IN_CASH: 'Paid in Cash',
});

export const LOAN_OPTION_TYPES_NUMERICAL_VALUE = Object.freeze({
  Finance: 0,
  'Paid in Cash': 1,
});

export const VA_History = Object.freeze({
  First: '1st Use',
  Repeat: 'Repeat use',
});

export const GOV_TYPES = Object.freeze({
  FHA: 'FHA',
  VA: 'VA',
  USDA: 'USDA',
});

export const SETTLEMENT_MONTHS = Object.freeze({
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
});

export const VA_RATE_TYPES = Object.freeze({
  PURCHASE: 'PURCHASE',
  CASHOUT: 'CASH_OUT',
  REFINANCE: 'REFINANCE',
});

export const MAX_NOTE_LENGTH = 512;

export const PRICING_ROUND = 'Pricing Rounded';
export const ROUNDING_RULE_NAME = 'Rounding Rule Adjustments';

export const GOV_TYPES_DISPLAY_NAME = Object.freeze({
  fhaFinancingOption: 'Financing Option',
  fhaFinanceAmount: 'Financed Amount',
  fhaTotalLoanAmount: 'Total Loan Amount',
  fhaMortgageInsurancePremiumAmount: 'MIP Amount',
  fhaMortgageInsurancePremium: 'MIP (%)',
  usdaFinanceAmount: 'Financed Amount',
  usdaFinancingOption: 'Financing Option',
  usdaGuaranteeFee: 'Guarantee Fee (%)',
  usdaGuaranteeFeeAmount: 'Guarantee Fee Amount',
  usdaTotalLoanAmount: 'Total Loan Amount',
  vaDownPaymentAmount: 'Down Payment Amount',
  vaDownPayment: 'Down Payment',
  vaFinanceAmount: 'Financed Amount',
  vaFinancingOption: 'Financing Option',
  vaFundingFee: 'Funding Fee (%)',
  vaFundingFeeAmount: 'Funding Fee Amount',
  vaFundingFeeExempt: 'Funding Fee Exempt',
  vaLoanHistory: 'VA History',
  vaTotalLoanAmount: 'Total Loan Amount',
});

export const SETTLEMENT_MONTH_INCLUSION_OPTIONS = Object.freeze({
  '0': 'Current',
  '1': '+ 1 month',
  '2': '+ 2 month',
});

export const InvestorExperienceKeys = Object.freeze({
  NOANSWER: 0,
  EXPERIENCEDINVESTOR: 1,
  FIRSTTIMEINVESTOR: 2,
});
export const LOCK_CONFIRMATION_DOCUMENT_STATUS = Object.freeze({
  PENDING: 0,
  PROCESSING: 1,
  RETRYING: 2,
  SUCCESS: 3,
  FAILED: 4,
  CANCELLED: 5,
});

export const PENDING_LOCK_CONFIRMATION_DOCUMENT_STATUSES = Object.freeze([
  LOCK_CONFIRMATION_DOCUMENT_STATUS.PENDING,
  LOCK_CONFIRMATION_DOCUMENT_STATUS.PROCESSING,
  LOCK_CONFIRMATION_DOCUMENT_STATUS.RETRYING,
]);

export const SECURITY_POOL_STATUS = Object.freeze({
  READY: 'Ready',
  NOT_READY: 'Not Ready',
});

export const SECURITY_POOL_STATUS_REASON = Object.freeze({
  HAS_EXPIRED_BIDS: 'Has Expired Bid',
  NOT_FILLED: 'Not Filled',
  NOT_DILUTED: 'Not Diluted',
});

export const PRICE_EXCEPTION_STATUS = Object.freeze({
  PENDING: 'Pending',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  AUTO_APPROVED: 'Auto-approved',
});

export const APPROVAL_TYPES = Object.freeze({
  DIRECT_APPROVAL: 'Direct Approval',
  CHAIN_APPROVAL: 'Chain Approval',
});

export const LOS_MAPPED_ELEMENT_SOURCE_TYPES = {
  str: 'text',
  int: 'number',
  decimal: 'text',
  date: 'date',
  datetime: 'datetime-local',
  bool: 'boolean',
};

export const dateDisplayTypes = ['date', 'datetime'];

export const INVITATION_STATUS = Object.freeze({
  INVITED: 'INVITED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
});

export const MAX_EXCESS_SERVICING = 1.1;
export const PRODUCT_CHANGE_ENUMS = Object.freeze({
  HistoricalPricing: 'HistoricalPricing',
  CurrentPricing: 'CurrentPricing',
  WorseCasePricing: 'WorseCasePricing',
});

export const PRODUCT_CHANGE_DISPLAY = Object.freeze({
  [PRODUCT_CHANGE_ENUMS.HistoricalPricing]: 'Historical',
  [PRODUCT_CHANGE_ENUMS.CurrentPricing]: 'Current Market',
  [PRODUCT_CHANGE_ENUMS.WorseCasePricing]: 'Worse Case',
});

export const PRICING_MODE_LOGIC_DISPLAY = Object.freeze({
  [PRODUCT_CHANGE_ENUMS.HistoricalPricing]: 'Worse Case - Historical Pricing',
  [PRODUCT_CHANGE_ENUMS.CurrentPricing]: 'Relock Reset - Current Pricing',
  [PRODUCT_CHANGE_ENUMS.WorseCasePricing]: 'Worse Case - Current Pricing',
});

export const PRODUCT_CHANGE_AUTO_APPROVED = Object.freeze({
  AutoApproved: true,
  NotAutoApproved: false,
});

export const MAX_PRODUCT_CHANGE_MAPPINGS_ALLOWED = 10000000;

export const STATUS_INDICATOR_TYPES = Object.freeze({
  ACTIVE: 'active',
  CHANGED: 'changed',
  COMPLETE: 'complete',
});
export const STATUS_INDICATOR_POSITIONS = Object.freeze({
  AFTER: 'after',
  BEFORE: 'before',
});

export const LOCK_CONFIRM = 'LOCK.CONFIRM';
export const LOCK_CONFIRMED = 'LOCK.CONFIRMED';
export const REPRICE_CONFIRM = 'REPRICE.CONFIRM';
export const REPRICE_CONFIRMED = 'REPRICE.CONFIRMED';
export const RELOCK_CONFIRM = 'RELOCK.CONFIRM';
export const RELOCK_CONFIRMED = 'RELOCK.CONFIRMED';
export const PRODUCT_CHANGE_CONFIRM = 'PRODUCT_CHANGE.CONFIRM';
export const PRODUCT_CHANGE_CONFIRMED = 'PRODUCT_CHANGE.CONFIRMED';
export const PRODUCT_CHANGE_PENDING = 'PRODUCT_CHANGE.PENDING';

export const LOCK_EXP_DATE_SCHED_RULE = Object.freeze({
  NEXT_BUSINESS_DAY: 0,
  PREVIOUS_BUSINESS_DAY: 1,
  ON_SELECTED_DAY: 2,
});

// Some of the values from LOAN_DATA_LABELS require formatting (so far, just money formatting)
export const LOAN_DATA_FORMATS = {
  loan: {
    amount: formatMoney,
    purchasePrice: formatMoney,
  },
  property: {
    estimatedValue: formatMoney,
  },
};

export const LOAN_DATA_LABELS = Object.freeze({
  changesetId: 'Changeset ID',
  audienceId: 'Audience ID',
  requestedBy: 'Requested By',
  reference: 'Reference',
  countyLoanLimitYearToUse: 'County Loan Limit Year to Use',
  baseRateSetId: 'Base Rate Set ID',
  borrower: Object.freeze({
    firstName: 'First Name',
    lastName: 'Last Name',
    fico: 'Borrower FICO',
    dtiRatio: 'DTI Ratio',
    citizenship: 'Citizenship',
    isFirstTimeHomeBuyer: 'First Time Home Buyer',
    isNonOccupancyBorrower: 'Non-Occupancy Borrower',
    isNonOccupancyCoborrower: 'Non-Occupancy Co-borrower',
    isSelfEmployed: 'Self Employed',
    incomeDocumentation: 'Income Documentation',
    assetDocumentation: 'Asset Documentation',
    employmentVerification: 'Employment Verification',
    monthsOfReserves: 'Mos. of Reserves',

    // Late Payment
    latePmt30x12: 'Late Payment 30x12',
    latePmt60x12: 'Late Payment 60x12',
    latePmt90x12: 'Late Payment 90x12',
    latePmt120x12: 'Late Payment 120x12',
    latePmt30x24: 'Late Payment 30x24',
    latePmt60x24: 'Late Payment 60x24',
    latePmt90x24: 'Late Payment 90x24',
    latePmt120x24: 'Late Payment 120x24',

    // DE == 'Derogatory Event'; BK == 'Bankrupt'/'Bankruptcy'; C == 'Chapter'
    deMonthsDIL: 'DE Mos. Deed in Lieu',
    deMonthsShortSale: 'DE Mos. Short Sale',
    deMonthsChargeOff: 'DE Mos. Charge Off',
    deMonthsBKC7: 'DE Mos. Bankruptcy Chapter 7',
    deMonthsBKC11: 'DE Mos. Bankruptcy Chapter 11',
    deMonthsBKC13: 'DE Mos. Bankruptcy Chapter 13',
    deMonthsLoanMod: 'DE Mos. Loan Mod',
    deMonthsNoticeOfDefault: 'DE Mos. Notice of Default',
    deMonthsForeclosure: 'DE Mos. Foreclosure',
    deMultBK: 'DE Multiple Bankruptcies',
    deMultEvents: 'DE Multiple Events',

    verificationMethod: 'Verification Method',
    fullDocMonths: 'Full Doc Months',
    bankStatementsNumberOfMonthsPersonal: 'Personal Bank Stmt. Mos.',
    bankStatementsNumberOfMonthsBusiness: 'Business Bank Stmt. Mos.',
    verificationOfEmploymentAmount: 'Verification of Employment Amount',
    assetQualificationAmount: 'Asset Qualification Amount',
    debtServiceCoverageRatio: 'Debt Service Coverage Ratio',
    investorExperience: 'Investor Experience',
    assetDepletionAmount: 'Asset Depletion Amount',
    bankStatementExpenseMethod: 'Bank Statement Expense Method',
    propertiesOwned: 'Properties Owned',
    multipleBorrowerPairs: 'Multiple Borrower Pairs',
    months1099: '1099 Mos.',
    residualIncome: 'Residual Income',
    creditGrade: 'Credit Grade',
    isNonTraditionalCredit: 'Non-Traditional Credit',
    isGiftFunds: 'Gift Funds',
    annualIncome: 'Annual Income',
  }),
  loan: Object.freeze({
    applicationDate: 'Loan Application Date',
    amount: 'Loan Amount',
    loanAmount: 'Loan Amount',
    purchasePrice: 'Loan Purchase Price',
    fhaTotalLoanAmount: 'FHA Total Loan Amount',
    fhaMortgageInsurancePremium: 'FHA MI Premium',
    fhaMortgageInsurancePremiumAmount: 'FHA MI Premium Amount',
    fhaFinanceAmount: 'FHA Finance Amount',
    fhaFinancingOption: 'Finance Financing Option',
    vaTotalLoanAmount: 'VA Total Loan Amount',
    vaDownPayment: 'VA Down Payment',
    vaDownPaymentAmount: 'VA Down Payment Amount',
    vaLoanHistory: 'VA Loan History',
    vaFundingFee: 'VA Funding Fee',
    vaFundingFeeAmount: 'VA Funding Fee Amount',
    vaFinanceAmount: 'VA Finance Amount',
    vaFinancingOption: 'VA Financing Option',
    vaFundingFeeExempt: 'VA Funding Fee Exempt',
    vaCashoutLTV: 'VA Cashout LTV',
    vaCashoutCLTV: 'VA Cashout CLTV',
    vaCashoutHCLTV: 'VA Cashout HCLTV',
    VACashoutMaxOfLTVAndCLTV: 'VA Cashout Max of LTV and CLTV',
    vaCashoutMaxOfLTVAndCLTVAndHCLTV:
      'VA Cashout Max of LTV and CLTV and HCLTV',
    usdaTotalLoanAmount: 'USDA Total Loan Amount',
    usdaGuaranteeFee: 'USDA Guarantee Fee',
    usdaGuaranteeFeeAmount: 'USDA Guarantee Fee Amount',
    usdaFinanceAmount: 'USDA Finance Amount',
    usdaFinancingOption: 'USDA Financing Option',
    purpose: 'Loan Purpose',
    refinancePurpose: 'Loan Refinance Purpose',
    cashOutAmount: 'Cashout Amount',
    ltv: 'LTV',
    tltv: 'TLTV',
    cltv: 'CLTV',
    hcltv: 'HCLTV',
    maxOfLTVandCLTV: 'Max of LTV and CLTV',
    maxOfTLTVandCLTV: 'Max of TLTV and CLTV',
    maxOfTLTVandHCLTV: 'Max of TLTV and HCLTV',
    maxOfLTVandCLTVandHCLTV: 'Max of LTV and CLTV and HCLTV',
    maxOfTLTVandCLTVandHCLTV: 'Max of TLTV and CLTV and HCLTV',
    impounds: 'Waive Escrow',
    servicerName: 'Loan Servicer Name',
    isRelocationLoan: 'Relocation Loan',
    isMortgageInsurancePaidByBorrower: 'MI Paid By Borrower',
    secondAmount: 'Second Amount',
    helocLineAmount: 'HELOC Line Amount',
    helocDrawAmount: 'HELOC Draw Amount',
    lenderFee: 'Lender Fee',
    rollLenderFee: 'Roll Lender Fee',
    prepaymentPenaltyPeriodMonths: 'Prepayment Penalty Period Months',
    prepaymentPenaltyStructure: 'Prepayment Penalty Period',
    prepaymentPenaltyStructureType: 'Prepayment Penalty Period Type',
    aus: 'AUS',
    position: 'Loan Position',
    streamlineRefinanceType: 'Streamline Refinance Type',
    estimatedClosingDate: 'Estimated Closing Date',
    isSecondInvestorSameAsFirst: '1st Lien Investor',
    isSecondCommunityLoan: 'Community Second',
    isSecondPiggyback: 'Piggyback',
  }),
  property: Object.freeze({
    estimatedValue: 'Estimated Value',
    appraisedValue: 'Appraised Value',
    propertyType: 'Property Type',
    propertyAttachmentType: 'Property Attachment Type',
    occupancy: 'Occupancy',
    units: 'Units',
    stories: 'Stories',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'City',
    state: 'State',
    stateFipsCode: 'State FIPS Code',
    county: 'County',
    countyFipsCode: 'County FIPS Code',
    zipCode: 'ZIP Code',
    zipCodePlusFour: 'ZIP+4 Code',
    lotSizeInAcres: 'Lot Size (Acres)',
    isNonWarrantableProject: 'Non-Warrantable',
    isCondotel: 'Condotel',
    isDecliningMarket: 'Declining Market',
    inspectionWaiver: 'Inspection Waiver',
    medianIncome: 'Median Income',
    msaCode: 'MSA Code',
    censusTract: 'Census Tract',
  }),
  brokerCompPlan: Object.freeze({
    fixedAmount: 'Broker Comp Plan Fixed Amount',
    percent: 'Broker Comp Plan Percent',
    minAmount: 'Broker Comp Plan Minimum Amount',
    maxAmount: 'Broker Comp Plan Maximum Amount',
    calculatedAdjustment: 'Broker Comp Plan Calculated Adjustment',
    calculatedAmount: 'Broker Comp Plan Calculated Amount',
    paidBy: 'Broker Comp Plan Paid By',
  }),
  search: Object.freeze({
    position: 'Position',
    desiredPrice: 'Desired Price',
    desiredRate: 'Desired Rate',
    desiredLockPeriod: 'Desired Lock Period',
    includeInterestOnlyProducts: 'Include Interest Only Products',
    prepaymentPenaltyPeriodInYears: 'Prepayment Penalty Period (Years)',
    productCodes: 'Product Codes',
    loanTypes: 'Loan Types',
    loanTerms: 'Loan Terms',
    customLoanTerm: 'Custom Loan Term',
    amortizationTypes: 'Amortization Types',
    armFixedTerms: 'Arm Fixed Terms',
  }),
});

export const WAIVE_ESCROW_NO = 2;
export const WAIVE_ESCROW_YES = 0;
export const WAIVE_ESCROW_PARTIAL = 1;
const PARTIAL = 'Partial';

export const WAIVE_ESCROW_OPTIONS = [
  { id: WAIVE_ESCROW_NO, text: NO },
  { id: WAIVE_ESCROW_YES, text: YES },
  { id: WAIVE_ESCROW_PARTIAL, text: PARTIAL },
];

export const PEToWaiveEscrow = Object.freeze({
  Full: NO,
  None: YES,
  Partial: PARTIAL,
});
export const PEImpoundValues = Object.freeze({
  Full: 2,
  None: 0,
  Partial: 1,
});
export const WORKFLOW_LOCK_SIDES = Object.freeze({
  BUY: 'buy',
  SELL: 'sell',
});

export const LOCK_DESK_OVERRIDE_MODE = 'lockDesk';

export const APPROVE_WORKFLOW_LABELS = Object.freeze({
  true: 'Request and Approve',
  false: 'Confirm',
});

export const ADJUSTMENTS_POPOVER_BOX_TOGGLE_VALUES = Object.freeze({
  BUY: 'buy',
  SELL: 'sell',
  BOTH: 'both',
});

export const ADJUSTMENT_ACTIONS = Object.freeze({
  CREATE: 0,
  MODIFY: 1,
  DELETE: 2,
});

// A whitelist of categories to keep updated in the popover. You may need to
// update this if anything is changed in `PERuleCategories`.
export const CATEGORY_WHITELIST = [
  PERuleCategories.Adjustment,
  PERuleCategories.Margin,
  PERuleCategories.SRP,
];

export const CANCEL = 'Cancel';

export const ROUTE_TO_BREADCRUMB_CONVERTERS = {
  ['lock-desk'](from, extraData) {
    const breadcrumb = [{ to: { name: 'lock-desk' }, text: 'Lock Desk' }];
    if (from.query?.lock_request) {
      breadcrumb.push({
        to: {
          ...from,
          name: 'lock-desk',
          query: {
            ...from?.query,
            from_breadcrumb: true,
          },
        },
        text: `Loan ${extraData?.loanNumber || 'Detail'}`,
      });
    }

    return breadcrumb;
  },
  ['lock-history'](from, extraData) {
    return [
      { to: { name: 'lock-desk' }, text: 'Lock Desk' },
      {
        to: { name: 'lock-history', params: { id: extraData.losLoanId } },
        text: `Loan ${extraData?.loanNumber || 'History'}`,
      },
    ];
  },
};

export const REPRICE_APPROVAL_OPTIONS = Object.freeze({
  NoPriceChange: 'NoPriceChange',
  All: 'All',
});

export const ADJUSTMENT_EDITED_MAP = {
  [`${ADJUSTMENT_TYPE.PRICE}-${WORKFLOW_LOCK_SIDES.BUY}`]:
    'hasPriceAdjustmentsEditedBuy',
  [`${ADJUSTMENT_TYPE.PRICE}-${WORKFLOW_LOCK_SIDES.SELL}`]:
    'hasPriceAdjustmentsEditedSell',
  [`${ADJUSTMENT_TYPE.RATE}-${WORKFLOW_LOCK_SIDES.BUY}`]:
    'hasRateAdjustmentsEditedBuy',
  [`${ADJUSTMENT_TYPE.RATE}-${WORKFLOW_LOCK_SIDES.SELL}`]:
    'hasRateAdjustmentsEditedSell',
};

export const OVERRIDE_REASON_MAX_LENGTH = 250;

export const MORTGAGE_INSURANCE_PREMIUM_TYPE = Object.freeze({
  BORROWER: 'Borrower Paid Monthly',
  SPLIT: 'Split Premium',
  SINGLE: 'Single Premium',
  LENDER: 'Lender Paid',
});

export const MORTGAGE_INSURANCE_PREMIUM_TYPE_PE3 = Object.freeze({
  BORROWER: 'BorrowerPaidMonthly',
  SPLIT: 'SplitPremium',
  SINGLE: 'SinglePremium',
  LENDER: 'LenderPaid',
});

export const COMPLIANCE_RESULT_VALUE = Object.freeze({
  PASS: 'Pass',
  FAIL: 'Fail',
  WARNING: 'Warning',
});

export const PEBankStatementExpenseValues = Object.freeze({
  None: 0,
  TwoYears: 1,
  OneYearTaxReturnAndProfitAndLossStatement: 2,
  OneYearCPAProvidedProfitAndLossStatement: 3,
});
export const PEBankStatementExpenseLabels = Object.freeze({
  None: 'None',
  TwoYears: '2 Years',
  OneYearTaxReturnAndProfitAndLossStatement: '1 Year Tax Return + P&L',
  OneYearCPAProvidedProfitAndLossStatement: '1 Year CPA P&L',
});
export const PEBankStatementExpenseOptions = createDropdownArr(
  PEBankStatementExpenseValues,
  PEBankStatementExpenseLabels,
);
export const DEFAULT_CUSTOM_LOCK_PERIOD = Object.freeze({
  isEnabled: false,
  excludeAll: false,
  excluded: [],
  added: [],
});

export const DEFAULT_CUSTOM_NOTE_RATE = Object.freeze({
  isEnabled: false,
  excludeAll: false,
  excluded: [],
  added: [],
});
/**
 * Maps the PE Rule fields to the state item PricingScenarioResult, from the pricing store,
 *  so we can display the pricing template fields on the rule statement.
 * - A string means we will display whatever value is in the template
 * - An object will have a path and options, which we use to get a human readable format
 */
export const RULE_FIELDS_TEMPLATE_MAP = Object.freeze({
  Loan: {
    Amount: 'loan.amount',
    AUS: { path: 'loan.aus', options: PEAusLabels, peValues: PEAusValues },
    CashOutAmount: 'loan.cashOutAmount',
    CLTV: 'loan.cltv',
    FHATotalLoanAmount: 'loan.fhaTotalLoanAmount',
    HCLTV: 'loan.hcltv',
    HELOCLineAmount: 'loan.helocLineAmount',
    HELOCDrawAmount: 'loan.helocDrawAmount',
    Impounds: {
      path: 'loan.impounds',
      options: PEToWaiveEscrow,
      peValues: PEImpoundValues,
    },
    IsMortgageInsurancePaidByBorrower: {
      path: 'loan.isMortgageInsurancePaidByBorrower',
      options: yesNoLabels,
    },
    IsRelocationLoan: {
      path: 'loan.isRelocationLoan',
      options: yesNoLabels,
    },
    LenderFee: 'loan.lenderFee',
    LTV: 'loan.ltv',
    MaxOfLTVandCLTV: 'loan.maxOfLTVandCLTV',
    MaxOfLTVandCLTVandHCLTV: 'loan.maxOfLTVandCLTVandHCLTV',
    Position: {
      path: 'loan.position',
      options: PELienPositionLabels,
      peValues: PELienPositionValues,
    },
    PrepaymentPenaltyPeriodMonths: 'loan.prepaymentPenaltyPeriodMonths',
    PrepaymentPenaltyStructure: {
      path: 'loan.prepaymentPenaltyStructure',
      options: PEPrepaymentPSLabels,
      peValues: PEPrepaymentPSValues,
    },
    Purpose: {
      path: 'loan.purpose',
      options: pePurposeTypesToStr,
      peValues: pePurposeTypesValues,
    },
    RefinancePurpose: {
      path: 'loan.refinancePurpose',
      options: PERefinaceTypesLabels,
      peValues: peRefinancePurposeTypesToId,
    },
    RollLenderFee: {
      path: 'loan.rollLenderFee',
      options: yesNoLabels,
    },
    FirstLienInvestor: {
      path: 'loan.ssSecondInvestorSameAsFirst',
      options: FirstInvestorLabels,
      peValues: PEFirstInvestorValues,
    },
    IsCommunitySecondLoan: {
      path: 'loan.isCommunitySecond',
      options: yesNoLabels,
    },
    IsPiggyBackLoan: {
      path: 'loan.isPiggyBackLoan',
      options: yesNoLabels,
    },
    SecondAmount: 'loan.secondAmount',
    USDATotalLoanAmount: 'loan.usdaTotalLoanAmount',
    VATotalLoanAmount: 'loan.vaTotalLoanAmount',
    VACashoutLTV: 'loan.vaCashoutLTV',
    VACashoutCLTV: 'loan.vaCashoutCLTV',
    VACashoutHCLTV: 'loan.vaCashoutHCLTV',
    VACashoutMaxOfLTVAndCLTV: 'loan.vaCashoutMaxOfLTVAndCLTV',
    VACashoutMaxOfLTVAndCLTVAndHCLTV: 'loan.vaCashoutMaxOfLTVAndCLTVAndHCLTV',
    StreamlineRefinanceType: {
      path: 'loan.streamlineRefinanceType',
      options: PeStreamlineRefinanceLabels,
      peValues: PeStreamlineRefinanceValues,
    },
    TemporaryBuydownType: {
      path: 'loan.temporaryBuydownType',
      options: PETemporaryBuydownTypeLabels,
      peValues: temporaryBuydownPeStringToEnum,
    },
    PropertyValue: 'loan.propertyValue',
    [PERuleFieldApplicationDate]: 'loan.applicationDate',
    [PERuleFieldFHAPriorEndorsementDate]: 'loan.fhaPriorEndorsementDate',
  },
  Borrower: {
    FirstName: 'borrower.firstName',
    LastName: 'borrower.lastName',
    FICO: 'borrower.fico',
    DTIRatio: 'borrower.dtiRatio',
    Citizenship: {
      path: 'borrower.citizenship',
      options: PECitizenshipLabels,
      peValues: PECitizenshipValues,
    },
    IsFirstTimeHomeBuyer: {
      path: 'borrower.isFirstTimeHomeBuyer',
      options: yesNoLabels,
    },
    IsNonOccupancyBorrower: {
      path: 'borrower.isNonOccupancyBorrower',
      options: yesNoLabels,
    },
    IsNonOccupancyCoborrower: {
      path: 'borrower.isNonOccupancyCoborrower',
      options: yesNoLabels,
    },
    IsSelfEmployed: {
      path: 'borrower.isSelfEmployed',
      options: yesNoLabels,
    },
    IncomeDocumentation: {
      path: 'borrower.incomeDocumentation',
      options: PEDocumentationVerificationLabels,
      peValues: PEDocumentationVerificationValues,
    },
    AssetDocumentation: {
      path: 'borrower.assetDocumentation',
      options: PEDocumentationVerificationLabels,
      peValues: PEDocumentationVerificationValues,
    },
    EmploymentVerification: {
      path: 'borrower.employmentVerification',
      options: PEDocumentationVerificationLabels,
      peValues: PEDocumentationVerificationValues,
    },
    MonthsOfReserves: 'borrower.monthsOfReserves',
    LatePmt30x12: 'borrower.latePmt30x12',
    LatePmt60x12: 'borrower.latePmt60x12',
    LatePmt90x12: 'borrower.latePmt90x12',
    LatePmt120x12: 'borrower.latePmt120x12',
    LatePmt30x24: 'borrower.latePmt30x24',
    LatePmt60x24: 'borrower.latePmt60x24',
    LatePmt90x24: 'borrower.latePmt90x24',
    LatePmt120x24: 'borrower.latePmt120x24',
    DEMonthsBKC: 'borrower.dEMonthsBKC7',
    DEMonthsDIL: 'borrower.dEMonthsDIL',
    DEMonthsShortSale: 'borrower.dEMonthsShortSale',
    DEMonthsChargeOff: 'borrower.dEMonthsChargeOff',
    DEMonthsBKC7or11: 'borrower.dEMonthsBKC7or11',
    DEMonthsBKC7: 'borrower.dEMonthsBKC7',
    DEMonthsBKC11: 'borrower.dEMonthsBKC11',
    DEMonthsBKC13: 'borrower.dEMonthsBKC13',
    DEMonthsLoanMod: 'borrower.dEMonthsLoanMod',
    DEMonthsNoticeOfDefault: 'borrower.dEMonthsNoticeOfDefault',
    DEMonthsForeclosure: 'borrower.dEMonthsForeclosure',
    DEMultBK: {
      path: 'borrower.deMultBK',
      options: yesNoLabels,
    },
    DEMultEvents: {
      path: 'borrower.dEMultEvents',
      options: yesNoLabels,
    },
    VerificationMethod: {
      path: 'borrower.verificationMethod',
      options: PEVerificationMethodsLabels,
      peValues: PEVerificationMethods,
    },
    [PE1099Months]: 'borrower.months1099',
    ResidualIncome: 'borrower.residualIncome',
    CreditGrade: {
      path: 'borrower.creditGrade',
      options: PECreditGradeLabels,
      peValues: PECreditGrade,
    },
    IsNonTraditionalCredit: {
      path: 'borrower.isNonTraditionalCredit',
      options: yesNoLabels,
    },
    IsGiftFunds: {
      path: 'borrower.isGiftFunds',
      options: yesNoLabels,
    },
    FullDocMonths: 'borrower.fullDocMonths',
    CPAPandLMonths: 'borrower.cpaPandLMonths',
    BankStatementsNumberOfMonthsPersonal:
      'borrower.bankStatementsNumberOfMonthsPersonal',
    BankStatementsNumberOfMonthsBusiness:
      'borrower.bankStatementsNumberOfMonthsBusiness',
    VerificationOfEmploymentAmount: 'borrower.verificationOfEmploymentAmount',
    AssetQualificationAmount: 'borrower.assetQualificationAmount',
    DebtServiceCoverageRatio: 'borrower.debtServiceCoverageRatio',
    AssetDepletionAmount: 'borrower.assetDepletionAmount',
    BankStatementExpenseMethod: {
      path: 'borrower.bankStatementExpenseMethod',
      options: PEBankStatementExpenseLabels,
      peValues: PEBankStatementExpenseValues,
    },
    PropertiesOwned: 'borrower.propertiesOwned',
    MultipleBorrowerPairs: {
      path: 'borrower.multipleBorrowerPairs',
      options: yesNoLabels,
    },
    InvestorExperience: {
      path: 'borrower.investorExperience',
      options: PEInvestorExperienceLabels,
      peValues: PEInvestorExperienceOptions,
    },
    AnnualIncome: 'borrower.annualIncome',
  },
  [PERuleGroupProperty]: {
    EstimatedValue: 'property.estimatedValue',
    AppraisedValue: 'property.appraisedValue',
    PropertyType: {
      path: 'property.propertyType',
      options: pePropertyTypesToStr,
      peValues: pePropertyTypesValues,
    },
    PropertyAttachmentType: 'property.propertyAttachmentType',
    Occupancy: {
      path: 'property.occupancy',
      options: peOccupancyTypesToStr,
      peValues: peOccupancyTypesValues,
    },
    [PERuleFieldUnitsKey]: 'property.units',
    Stories: 'property.stories',
    AddressLine1: 'property.addressLine1',
    AddressLine2: 'property.addressLine2',
    City: 'property.city',
    [PERuleFieldsState]: 'property.state',
    StateFipsCode: 'property.stateFipsCode',
    [PERuleFieldsCounty]: 'property.county',
    [PERuleFieldsCountyFipsCode]: 'property.countyFipsCode',
    ZipCode: 'property.zipCode',
    LotSizeInAcres: 'property.lotSizeInAcres',
    IsNonWarrantableProject: {
      path: 'property.isNonWarrantableProject',
      options: yesNoLabels,
    },
    IsCondotel: {
      path: 'property.isCondotel',
      options: yesNoLabels,
    },
    IsDecliningMarket: {
      path: 'property.isDecliningMarket',
      options: yesNoLabels,
    },
    InspectionWaiver: {
      path: 'property.inspectionWaiver',
      options: yesNoLabels,
    },
    MedianIncome: 'property.medianIncome',
    MSACode: 'property.msaCode',
    CensusTract: 'property.censusTract',
  },
  [PERuleFieldsBrokerCompPlan]: {
    FixedAmount: 'brokerCompPlan.fixedAmount',
    Percent: 'brokerCompPlan.percent',
    MinAmount: 'brokerCompPlan.minAmount',
    MaxAmount: 'brokerCompPlan.maxAmount',
    CalculatedAmount: 'brokerCompPlan.calculatedAmount',
    PaidBy: {
      path: 'brokerCompPlan.paidBy',
      options: PEBrokerCompLabels,
      peValues: PEBrokerCompValues,
    },
  },
  Product: {
    LTV: 'loan.ltv',
  },
});

export const BEFORE_OR_AFTER_FINAL_PRICE = Object.freeze({
  BEFORE: 'BeforeFinalPrice',
  AFTER: 'AfterFinalPrice',
});

export const LOS = Object.freeze({
  ENCOMPASS: 'ENCOMPASS',
  MERIDIAN_LINK: 'MERIDIAN_LINK',
  MORTGAGE_DIRECTOR: 'MORTGAGE_DIRECTOR',
  BYTE: 'BYTE',
});

export const OP_NAME = Object.freeze({
  EQ: '=',
  NE: '!=',
  LT: '<',
  GT: '>',
  LTE: '<=',
  GTE: '>=',
});
export const OP_LABEL = Object.freeze({
  [OP_NAME.EQ]: '=',
  [OP_NAME.NE]: '≠',
  [OP_NAME.LT]: '<',
  [OP_NAME.GT]: '>',
  [OP_NAME.LTE]: '≤',
  [OP_NAME.GTE]: '≥',
});

export const BULK_MODIFY_MODE = Object.freeze({
  ADD: 'ADD',
  REMOVE: 'REMOVE',
});

export const AUTO_PUBLISH_PREFERENCES = Object.freeze({
  DO_NOT_INCLUDE: 'DO_NOT_INCLUDE',
  USE_LAST_RATE_SHEET: 'USE_LAST_RATE_SHEET',
});

export const MI_RATE_CARDS_PROVIDERS = Object.freeze({
  NationalMI: 'National MI',
  MGIC: 'MGIC',
  ArchMI: 'Arch MI',
  Radian: 'Radian',
  Enact: 'Enact',
  Essent: 'Essent',
  CUSTOM_PROVIDER_1: 'Custom Provider 1',
  CUSTOM_PROVIDER_2: 'Custom Provider 2',
  CUSTOM_PROVIDER_3: 'Custom Provider 3',
  CUSTOM_PROVIDER_4: 'Custom Provider 4',
  CUSTOM_PROVIDER_5: 'Custom Provider 5',
});

export const MI_RATE_CARDS_PRODUCT_CATEGORIES = Object.freeze({
  BorrowerPaidMonthly: 'Borrower Paid Monthly',
  SplitPremium: 'Split Premium',
  SinglePremium: 'Single Premium',
  LenderPaid: 'Lender Paid',
});

export const MI_RATE_CARDS_PRODUCT_CATEGORIES_SHORTENED = Object.freeze({
  BorrowerPaidMonthly: 'BP Monthly',
  SplitPremium: 'Split Premium',
  SinglePremium: 'Single Premium',
  LenderPaid: 'Lender Paid',
});

export const MI_RATE_CARDS_IS_FINANCED_OPTIONS = Object.freeze([
  { id: true, text: 'Finance' },
  { id: false, text: 'Not Financed' },
]);

export const PARTNER_PRICING_STATUS = Object.freeze({
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
});

export const PELockDeskWorkflowUnit = Object.freeze({
  PERCENT: 'PERCENT',
  DOLLAR: 'DOLLAR',
});

export const LockDeskWorkflowUnitLabels = Object.freeze({
  [PELockDeskWorkflowUnit.PERCENT]: '%',
  [PELockDeskWorkflowUnit.DOLLAR]: '$',
});

export const PELockDeskWorkflowBuySidePriceCalculationRuleType = Object.freeze({
  PRICE_CAN_NOT_EXCEED_CURRENT_BUY_PRICE:
    'PRICE_CAN_NOT_EXCEED_CURRENT_BUY_PRICE',
  TAKES_CURRENT_MARKET_PRICE: 'TAKES_CURRENT_MARKET_PRICE',
});

export const CalculationRuleTypeLabels = Object.freeze({
  [PELockDeskWorkflowBuySidePriceCalculationRuleType.PRICE_CAN_NOT_EXCEED_CURRENT_BUY_PRICE]:
    'Price can not exceed current buy price',
  [PELockDeskWorkflowBuySidePriceCalculationRuleType.TAKES_CURRENT_MARKET_PRICE]:
    'takes current market price',
});

export const PELockDeskWorkflowCalculationLockPeriodType = Object.freeze({
  SAME_AS_INITIAL_LOCK: 'SAME_AS_INITIAL_LOCK',
  CALCULATE_USING_SPECIFIC_LOCK_PERIOD: 'CALCULATE_USING_SPECIFIC_LOCK_PERIOD',
});

export const CalculationLockPeriodTypeLabels = Object.freeze({
  [PELockDeskWorkflowCalculationLockPeriodType.SAME_AS_INITIAL_LOCK]:
    'Same as initial lock',
  [PELockDeskWorkflowCalculationLockPeriodType.CALCULATE_USING_SPECIFIC_LOCK_PERIOD]:
    'Calculate using specific lock period',
});
