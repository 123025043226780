<template>
  <div class="typing-indicator">
    {{ typingText }}
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </div>
</template>

<script>
export default {
  name: 'TypingIndicator',
  props: {
    typingText: {
      type: String,
      default: 'Thinking',
    },
  },
};
</script>

<style lang="scss">
.typing-indicator {
  display: inline-block;
}

.typing-indicator span {
  display: inline-block;
  margin-right: 2px;
  opacity: 0;
  animation: typingIndicator 1s linear infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.4s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes typingIndicator {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
