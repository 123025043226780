export const ADD_MESSAGE = 'addMessage';
export const REMOVE_STREAMING_MESSAGE = 'removeStreamingMessage';
export const UPDATE_CURRENT_MESSAGE = 'updateCurrentMessage';
export const SET_MESSAGE_REACTION = 'setMessageReaction';
export const ADD_ACTION_TO_CURRENT_MESSAGE = 'addActionToCurrentMessage';
export const ADD_MESSAGE_TO_HISTORY = 'addMessageToHistory';
export const SET_VOCAL_RESPONSE_ENABLED = 'setVocalResponseEnabled';
export const SET_AUTO_PRICING_ENABLED = 'setAutoPricingEnabled';
export const SET_LOAN_TRANSLATION_SCHEMA = 'setLoanTranslationSchema';
export const SET_BORROWER_TRANSLATION_SCHEMA = 'setBorrowerTranslationSchema';
export const SET_PROPERTY_TRANSLATION_SCHEMA = 'setPropertyTranslationSchema';
export const SET_SEARCH_TRANSLATION_SCHEMA = 'setSearchTranslationSchema';
export const SET_TEMPLATE = 'setTemplate';
export const SET_IS_SENDING = 'setIsSending';
export const SET_ELIGIBLE_PRODUCTS = 'setEligibleProducts';
export const SET_BEST_PRICES = 'setBestPrices';
export const SET_PRICING_REQUESTED = 'setPricingRequested';
export const SET_INELIGIBLE_PRODUCTS = 'setIneligibleProducts';
export const SET_SCENARIO_RETRIEVAL_OPTIONS = 'setScenarioRetrievalOptions';
export const SET_CUSTOM_PARAMETERS = 'setCustomParameters';
export const SET_PRICING_SCENARIO = 'setPricingScenario';
export const SET_OPTION_MAPS = 'setOptionMaps';
export const SET_CURRENT_PRICING_PARAMETERS = 'setCurrentPricingParameters';
export const ADD_ACTIVE_REQUEST_CONTROLLER = 'addActiveRequestController';
export const CLEAR_ACTIVE_REQUEST_CONTROLLERS = 'clearActiveRequestControllers';
export const REMOVE_ACTIVE_REQUEST_CONTROLLER = 'removeActiveRequestController';
export const SET_LATEST_TRAFFIC_COP_CLASSIFICATION =
  'setLatestTrafficCopClassification';
export const SET_SUGGESTED_ACTIONS = 'setSuggestedActions';
export const ADD_USED_SUGGESTED_ACTION_CATEGORY =
  'addUsedSuggestedActionCategory';
export const CLEAR_USED_SUGGESTED_ACTION_CATEGORIES =
  'clearUsedSuggestedActionCategories';
export const STOP_AUDIO = 'stopAudio';
export const PLAY_AUDIO = 'playAudio';
export const SET_IS_STREAMING = 'setIsStreaming';
export const SET_STREAMING_MESSAGE = 'setStreamingMessage';

export const COPILOT_ACTIONS = {
  resetPageBasedState: 'resetPageBasedState',
  setIsSending: 'setIsSending',
};

export const COPILOT_GETTERS = {
  currentMessage: 'currentMessage',
  allMessages: 'allMessages',
  currentHistory: 'currentHistory',
  historyLength: 'historyLength',
  jsonHistoryLength: 'jsonHistoryLength',
  trafficCopHistoryLength: 'trafficCopHistoryLength',
  ineligibilityHistoryLength: 'ineligibilityHistoryLength',
  botName: 'botName',
  autoPricingEnabled: 'autoPricingEnabled',
  vocalResponseEnabled: 'vocalResponseEnabled',
  currentlyPlayingAudio: 'currentlyPlayingAudio',
  loanTranslationSchema: 'loanTranslationSchema',
  borrowerTranslationSchema: 'borrowerTranslationSchema',
  propertyTranslationSchema: 'propertyTranslationSchema',
  searchTranslationSchema: 'searchTranslationSchema',
  template: 'template',
  customParameters: 'customParameters',
  isSending: 'isSending',
  eligibleProducts: 'eligibleProducts',
  bestPrices: 'bestPrices',
  pricingRequested: 'pricingRequested',
  ineligibleProducts: 'ineligibleProducts',
  scenarioRetrievalOptions: 'scenarioRetrievalOptions',
  pricingScenario: 'pricingScenario',
  optionMaps: 'optionMaps',
  currentPricingParameters: 'currentPricingParameters',
  latestTrafficCopClassification: 'latestTrafficCopClassification',
  suggestedActions: 'suggestedActions',
  usedSuggestedActionCategories: 'usedSuggestedActionCategories',
  activeRequestControllers: 'activeRequestControllers',
  isStreaming: 'isStreaming',
  isSaveLoadScenarioAvailable: 'isSaveLoadScenarioAvailable',
};

const greetingMessage = {
  sender: 'POLL-E',
  text: 'Hi, I’m Polly AI. I can help you get more done, faster. How can I help you today?',
};

const initialState = {
  currentMessage: null,
  isSending: false,
  allMessages: [greetingMessage],
  currentHistory: [
    {
      role: 'assistant',
      content: greetingMessage.text,
      sentAt: new Date(),
    },
  ],
  historyLength: 12,
  jsonHistoryLength: 2,
  trafficCopHistoryLength: 2,
  ineligibilityHistoryLength: 0,
  botName: 'Polly AI',
  autoPricingEnabled: false,
  vocalResponseEnabled: false,
  currentlyPlayingAudio: null,
  loanTranslationSchema: null,
  borrowerTranslationSchema: null,
  propertyTranslationSchema: null,
  searchTranslationSchema: null,
  scenarioRetrievalOptions: {
    page_size: 100,
  },
  template: {},
  pricingTemplate: {},
  customParameters: {},
  optionMaps: {},
  pricingRequested: false,
  bestPrices: null,
  eligibleProducts: null,
  ineligibleProducts: null,
  pricingScenario: null,
  currentPricingParameters: null,
  latestTrafficCopClassification: null,
  suggestedActions: [],
  usedSuggestedActionCategories: [],
  activeRequestControllers: [],
  isStreaming: false,
};

const actions = {
  [COPILOT_ACTIONS.resetPageBasedState]({ commit }) {
    commit(SET_ELIGIBLE_PRODUCTS, null);
    commit(SET_BEST_PRICES, null);
    commit(SET_PRICING_REQUESTED, false);
    commit(SET_INELIGIBLE_PRODUCTS, null);
    commit(SET_PRICING_SCENARIO, null);
    commit(SET_OPTION_MAPS, {});
    commit(SET_CURRENT_PRICING_PARAMETERS, null);
    commit(SET_LOAN_TRANSLATION_SCHEMA, null);
    commit(SET_BORROWER_TRANSLATION_SCHEMA, null);
    commit(SET_PROPERTY_TRANSLATION_SCHEMA, null);
    commit(SET_SEARCH_TRANSLATION_SCHEMA, null);
    commit(SET_TEMPLATE, {});
    commit(SET_CUSTOM_PARAMETERS, {});
    commit(SET_PRICING_SCENARIO, null);
  },
  [COPILOT_ACTIONS.setIsSending]({ commit }, isSending) {
    if (isSending) {
      commit(SET_SUGGESTED_ACTIONS, []);
    }
    commit(SET_IS_SENDING, isSending);
  },
};

const getters = {
  [COPILOT_GETTERS.currentMessage]: state => state.currentMessage,
  [COPILOT_GETTERS.allMessages]: state => state.allMessages,
  [COPILOT_GETTERS.currentHistory]: state => state.currentHistory,
  [COPILOT_GETTERS.historyLength]: state => state.historyLength,
  [COPILOT_GETTERS.jsonHistoryLength]: state => state.jsonHistoryLength,
  [COPILOT_GETTERS.trafficCopHistoryLength]: state =>
    state.trafficCopHistoryLength,
  [COPILOT_GETTERS.ineligibilityHistoryLength]: state =>
    state.ineligibilityHistoryLength,
  [COPILOT_GETTERS.botName]: state => state.botName,
  [COPILOT_GETTERS.autoPricingEnabled]: state => state.autoPricingEnabled,
  [COPILOT_GETTERS.vocalResponseEnabled]: state => state.vocalResponseEnabled,
  [COPILOT_GETTERS.currentlyPlayingAudio]: state => state.currentlyPlayingAudio,
  [COPILOT_GETTERS.loanTranslationSchema]: state => state.loanTranslationSchema,
  [COPILOT_GETTERS.borrowerTranslationSchema]: state =>
    state.borrowerTranslationSchema,
  [COPILOT_GETTERS.propertyTranslationSchema]: state =>
    state.propertyTranslationSchema,
  [COPILOT_GETTERS.searchTranslationSchema]: state =>
    state.searchTranslationSchema,
  [COPILOT_GETTERS.template]: state => state.template,
  [COPILOT_GETTERS.customParameters]: state => state.customParameters,
  [COPILOT_GETTERS.isSending]: state => state.isSending,
  [COPILOT_GETTERS.eligibleProducts]: state => state.eligibleProducts,
  [COPILOT_GETTERS.bestPrices]: state => state.bestPrices,
  [COPILOT_GETTERS.pricingRequested]: state => state.pricingRequested,
  [COPILOT_GETTERS.ineligibleProducts]: state => state.ineligibleProducts,
  [COPILOT_GETTERS.scenarioRetrievalOptions]: state =>
    state.scenarioRetrievalOptions,
  [COPILOT_GETTERS.pricingScenario]: state => state.pricingScenario,
  [COPILOT_GETTERS.optionMaps]: state => state.optionMaps,
  [COPILOT_GETTERS.currentPricingParameters]: state =>
    state.currentPricingParameters,
  [COPILOT_GETTERS.latestTrafficCopClassification]: state =>
    state.latestTrafficCopClassification,
  [COPILOT_GETTERS.suggestedActions]: state => state.suggestedActions,
  [COPILOT_GETTERS.usedSuggestedActionCategories]: state =>
    state.usedSuggestedActionCategories,
  [COPILOT_GETTERS.activeRequestControllers]: state =>
    state.activeRequestControllers,
  [COPILOT_GETTERS.isStreaming]: state => state.isStreaming,
  [COPILOT_GETTERS.isSaveLoadScenarioAvailable]: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => !rootGetters['core/canAccessPriceUIOnly'],
};

export const mutations = {
  [ADD_MESSAGE](state, message) {
    state.allMessages.push(message);
  },
  [REMOVE_STREAMING_MESSAGE](state) {
    const messageIndex = state.allMessages.findIndex(
      msg => msg.id === null && !msg.user,
    );
    if (messageIndex !== -1) {
      state.allMessages.splice(messageIndex, 1);
    }
  },
  [SET_MESSAGE_REACTION](state, { id, reaction }) {
    const message = state.allMessages.find(msg => msg.id === id);
    message.reaction = reaction;
  },
  [UPDATE_CURRENT_MESSAGE](state, currentMessage) {
    state.currentMessage = currentMessage;
  },
  [ADD_MESSAGE_TO_HISTORY](state, message) {
    state.currentHistory.push(message);

    if (state.currentHistory.length > state.historyLength) {
      state.currentHistory.shift();
    }
  },
  [SET_VOCAL_RESPONSE_ENABLED](state, enabled) {
    state.vocalResponseEnabled = enabled;
  },
  [SET_AUTO_PRICING_ENABLED](state, enabled) {
    state.autoPricingEnabled = enabled;
  },
  [STOP_AUDIO](state) {
    if (state.currentlyPlayingAudio) {
      state.currentlyPlayingAudio.pause();
      state.currentlyPlayingAudio = null;
    }
  },
  [PLAY_AUDIO](state, audioUrl) {
    // Create a new Audio object and set its source to the URL
    state.currentlyPlayingAudio = new Audio(audioUrl);

    // Play the audio
    state.currentlyPlayingAudio.play();
  },
  [ADD_ACTION_TO_CURRENT_MESSAGE](state, action) {
    if (!state.currentMessage.actions) {
      state.currentMessage.actions = [];
    }
    state.currentMessage.actions.push(action);
  },
  [SET_LOAN_TRANSLATION_SCHEMA](state, schema) {
    state.loanTranslationSchema = schema;
  },
  [SET_BORROWER_TRANSLATION_SCHEMA](state, schema) {
    state.borrowerTranslationSchema = schema;
  },
  [SET_PROPERTY_TRANSLATION_SCHEMA](state, schema) {
    state.propertyTranslationSchema = schema;
  },
  [SET_SEARCH_TRANSLATION_SCHEMA](state, schema) {
    state.searchTranslationSchema = schema;
  },
  [SET_IS_SENDING](state, isSending) {
    state.isSending = isSending;
  },
  [SET_TEMPLATE](state, template) {
    state.template = template;
  },
  [SET_ELIGIBLE_PRODUCTS](state, products) {
    state.eligibleProducts = products;
  },
  [SET_BEST_PRICES](state, prices) {
    state.bestPrices = prices;
  },
  [SET_PRICING_REQUESTED](state, requested) {
    state.pricingRequested = requested;
  },
  [SET_INELIGIBLE_PRODUCTS](state, products) {
    state.ineligibleProducts = products;
  },
  [SET_SCENARIO_RETRIEVAL_OPTIONS](state, options) {
    state.scenarioRetrievalOptions = options;
  },
  [SET_CUSTOM_PARAMETERS](state, customParameters) {
    state.customParameters = customParameters;
  },
  [SET_PRICING_SCENARIO](state, scenario) {
    state.pricingScenario = scenario;
  },
  [SET_OPTION_MAPS](state, optionMaps) {
    state.optionMaps = optionMaps;
  },
  [SET_CURRENT_PRICING_PARAMETERS](state, currentPricingParameters) {
    state.currentPricingParameters = currentPricingParameters;
  },
  [SET_LATEST_TRAFFIC_COP_CLASSIFICATION](state, classification) {
    state.latestTrafficCopClassification = classification;
  },
  [SET_SUGGESTED_ACTIONS](state, actions) {
    state.suggestedActions = actions;
  },
  [ADD_USED_SUGGESTED_ACTION_CATEGORY](state, category) {
    state.usedSuggestedActionCategories.push(category);
  },
  [CLEAR_USED_SUGGESTED_ACTION_CATEGORIES](state) {
    state.usedSuggestedActionCategories = [];
  },
  [ADD_ACTIVE_REQUEST_CONTROLLER](state, controller) {
    state.activeRequestControllers.push(controller);
  },
  [CLEAR_ACTIVE_REQUEST_CONTROLLERS](state) {
    state.activeRequestControllers = [];
  },
  [REMOVE_ACTIVE_REQUEST_CONTROLLER](state, controller) {
    const index = state.activeRequestControllers.indexOf(controller);
    if (index !== -1) {
      state.activeRequestControllers.splice(index, 1);
    }
  },
  [SET_IS_STREAMING](state, isStreaming) {
    state.isStreaming = isStreaming;
  },
  [SET_STREAMING_MESSAGE](state, message) {
    const lastMessage = state.allMessages.findLast(
      msg => msg.id === null && !msg.user,
    );
    if (lastMessage) {
      Object.assign(lastMessage, message);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
