import { isBuyApp, isSellApp, isDeliverApp } from '@shared/utils/routing.js';

const makePartnerOption = partner => ({
  [partner.is_active_partner ? 'text' : 'html']: partner.is_active_partner
    ? partner.name
    : `${partner.name} <span class="text-black-50">(Inactive)</span>`,
  value: partner.id,
});

function initSellAppFilter(partners, partnerFilter) {
  // Create Agency group
  const agencyOptions =
    partners?.filter(partner => partner.is_agency).map(makePartnerOption) || [];

  const agencyGroup = {
    name: 'Agency',
    label: 'Agency',
    key: 'agency_ids',
    options: agencyOptions,
    selected: agencyOptions.map(o => o.value),
    subGroups: [],
  };

  // Create Servicing group
  const servicerGroupOptions = [
    { text: 'Retained', value: 'retained' },
    ...(partners
      ?.filter(partner => partner.is_servicer)
      .map(makePartnerOption) || []),
  ];

  const servicerGroup = {
    name: 'Servicing',
    label: 'Servicing',
    key: 'servicing_type_ids',
    hideSelectAll: true,
    withoutIndent: true,
    options: servicerGroupOptions,
    selected: servicerGroupOptions.map(o => o.value),
  };

  if (servicerGroupOptions.length > 0) {
    agencyGroup.subGroups.push(servicerGroup);
  }

  partnerFilter.groups.push(agencyGroup);

  // Create Aggregator group
  const aggregatorOptions =
    partners
      ?.filter(partner => !partner.is_agency && !partner.is_servicer)
      .map(makePartnerOption) || [];

  const aggregatorGroup = {
    name: 'Aggregators',
    label: 'Aggregators',
    key: 'aggregator_ids',
    options: aggregatorOptions,
    selected: aggregatorOptions.map(o => o.value),
  };

  partnerFilter.groups.push(aggregatorGroup);
}

function initBuyAppFilter(partners, partnerFilter) {
  // Create Sellers group
  const sellerOptions =
    partners
      ?.filter(partner => !partner.is_agency && !partner.is_servicer)
      .map(makePartnerOption) || [];

  const sellerGroup = {
    name: 'Sellers',
    key: 'seller_ids',
    withoutIndent: true,
    options: sellerOptions,
    selected: sellerOptions.map(o => o.value),
  };

  partnerFilter.groups.push(sellerGroup);
}

export default {
  namespaced: true,
  state: {
    partnerFilter: {
      groups: [],
    },
  },
  getters: {
    partnerFilter: state => state.partnerFilter,
    partnerFilterSelected(state) {
      const returnValue = {};

      state.partnerFilter.groups.forEach(group => {
        returnValue[group.key] = group.selected;
        group.subGroups &&
          group.subGroups.forEach(subGroup => {
            returnValue[subGroup.key] = subGroup.selected;
          });
      });

      return returnValue;
    },
  },
  mutations: {
    setPartnerFilter(state, filter) {
      state.partnerFilter = filter;
    },
    setPartnerFilterSelected(state, selected) {
      state.partnerFilter.groups.forEach(group => {
        if (Object.prototype.hasOwnProperty.call(selected, group.key)) {
          group.selected = selected[group.key];
        }
        group.subGroups &&
          group.subGroups.forEach(subGroup => {
            if (Object.prototype.hasOwnProperty.call(selected, subGroup.key)) {
              subGroup.selected = selected[subGroup.key];
            }
          });
      });
    },
  },
  actions: {
    async initPartnerFilter(
      { commit, dispatch, getters, rootGetters },
      { showInactive = true, storageKey = null } = {},
    ) {
      let partners = rootGetters['core/partners'];
      const forSeller = isSellApp() || isDeliverApp();
      if (!partners || !partners.length) {
        await dispatch('core/getPartners', { forSeller }, { root: true });
        partners = rootGetters['core/partners'];
      }

      const partnerFilter = getters.partnerFilter;
      partnerFilter.groups = [];

      if (!showInactive) {
        partners = partners.filter(partner => partner.is_active_partner);
      }

      if (forSeller) {
        initSellAppFilter(partners, partnerFilter);
      }

      if (isBuyApp()) {
        initBuyAppFilter(partners, partnerFilter);
      }

      commit('setPartnerFilter', partnerFilter);

      if (storageKey && this._vm.$storage.get(storageKey)?.groups?.length) {
        const savedFilter = this._vm.$storage.get(storageKey);
        commit(
          'setPartnerFilterSelected',
          savedFilter.groups.reduce((acc, group) => {
            acc[group.key] = group.selected;
            group.subGroups?.forEach(subGroup => {
              acc[subGroup.key] = subGroup.selected;
            });
            return acc;
          }, {}),
        );
      }
    },
  },
};
