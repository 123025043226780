<template>
  <div class="suggested-actions">
    <button
      v-for="(action, index) in actions"
      :key="index"
      class="action-button"
      @click="sendSuggestion(action)"
    >
      {{ action.text }}
    </button>
  </div>
</template>

<script>
import {
  ADD_USED_SUGGESTED_ACTION_CATEGORY,
  COPILOT_GETTERS,
  SET_SUGGESTED_ACTIONS,
} from '@src/shared/store/modules/copilot';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SuggestedActions',
  computed: {
    ...mapGetters({
      actions: `copilot/${COPILOT_GETTERS.suggestedActions}`,
      usedActions: `copilot/${COPILOT_GETTERS.usedSuggestedActions}`,
    }),
  },
  methods: {
    sendSuggestion(action) {
      this.$nliService.initiateCopilotInteraction(action.text);
      this.setSuggestedActions([]);
      this.addUsedSuggestedActionCategory(action.category);
    },
    ...mapMutations({
      addUsedSuggestedActionCategory: `copilot/${ADD_USED_SUGGESTED_ACTION_CATEGORY}`,
      setSuggestedActions: `copilot/${SET_SUGGESTED_ACTIONS}`,
    }),
  },
};
</script>

<style scoped>
.suggested-actions {
  display: flex;
  gap: 8px 4px;
  margin: 5px;
  flex-wrap: wrap;
}

.action-button {
  height: 32px;
  background-color: #f5f3ff;
  border: 1px solid #dcd6ff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  padding: 20px 12px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-button:hover {
  background-color: #e0dbff;
  border-color: #bbb7ff;
}

.action-button:active {
  background-color: #dcd6ff;
  border-color: #a99eff;
}
</style>
