<template>
  <px-tree-view-multiselect
    :groups="partnerFilter.groups"
    label="Partners"
    :right="right"
    @applyFilter="runAction"
    @change="changeGroups"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'PxPartnersMultiSelect',
  props: {
    action: { type: Function, required: true },
    right: { type: Boolean, default: false },
    storageKey: { type: String, default: null },
  },
  computed: {
    ...mapGetters({
      partnerFilter: 'partnerFilter/partnerFilter',
      partnerFilterSelected: 'partnerFilter/partnerFilterSelected',
    }),
    noFilter() {
      return Object.values(this.partnerFilterSelected).flat().length === 0;
    },
  },
  methods: {
    ...mapMutations({
      setPartnerFilter: 'partnerFilter/setPartnerFilter',
    }),
    changeGroups(groups) {
      this.partnerFilter.groups = groups;
      this.setPartnerFilter(this.partnerFilter);

      if (this.storageKey) {
        this.$storage.set(this.storageKey, this.partnerFilter);
      }
    },
    runAction() {
      if (this.noFilter) {
        this.$notify({
          title: 'Please select one or more partner options',
          type: 'warn',
        });
      } else {
        this.action();
      }
    },
  },
};
</script>
